import About from "../../Pages/About/About";
import Blogs from "../../Pages/Blogs/Blogs";
import Contact from "../../Pages/Contact/Contact";
import Course from "../../Pages/Course/Course";
import Founder from "../../Pages/Founder/Founder";
import Images from "../../Pages/Gallery/Image/Image";
import Videos from "../../Pages/Gallery/Videos/Videos";
import Home from "../../Pages/Home/Home";
import ManageBlogs from "../../Pages/Manage/Blogs/ManageBlogs";
import ManageCourse from "../../Pages/Manage/Course/ManageCourse";
import ManageEnqiure from "../../Pages/Manage/Enqiure/Enqiure";
import ManageImage from "../../Pages/Manage/Image/ManageImage";
import ManageFounder from "../../Pages/Manage/ManageFounder/ManageFounder";
import ManageQuotes from "../../Pages/Manage/Quotes/ManageQuotes";
import ManageRegister from "../../Pages/Manage/Register/Register";
import ManageReview from "../../Pages/Manage/Reviews/ManageReviews";
import ManageVideos from "../../Pages/Manage/Videos/MangeVideos";
import Quotes from "../../Pages/quotes/Quotes";
import Reviews from "../../Pages/Reviews/Reviews";
import UpdatePassword from "../../Pages/updatePassword/updatePassword";
import NotFound from "../NotFound/NotFound";



const route = [
  { path: "/", element: <Home /> },
  { path: "/update-password", element: <UpdatePassword/> },
  {path:"/gallery/videos",element:<Videos/>},
  {path:"/gallery/images",element:<Images/>},
  {path:"/manage/images",element:<ManageImage/>},
  {path:"/manage/videos",element:<ManageVideos/>},
  {path:"/reviews",element:<Reviews/>},
  {path:"/manage/review",element:<ManageReview/>},
  {path:"/course",element:<Course/>},
  {path:"/blogs",element:<Blogs/>},
  {path:"/manage/contact",element:<Contact/>},
  {path:"/manage/register",element:<ManageRegister/>},
  {path:"/manage/enqiure",element:<ManageEnqiure/>},
  {path:"/manage/blogs",element:<ManageBlogs/>},
  {path:"/manage/course",element:<ManageCourse/>},
  {path:"/quotes",element:<Quotes/>},
  {path:"/manage/quotes",element:<ManageQuotes/>},
  {path:"/founder",element:<Founder/>},
  {path:"/manage/founder",element:<ManageFounder/>},
  {path:"/manage/about",element:<About/>},
  {path:"*",element:<NotFound/>}
];

export default route;
