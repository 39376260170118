import React, { useState, useEffect } from "react";
import client from "../../../Common/Client/Client";
import { Link } from "react-router-dom";
import { message, Popconfirm } from "antd";
import { Button, styled,TextField,Box } from "@mui/material";
import { Modal, Form } from "react-bootstrap";
import { FormLabel } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Loader from "../../../Common/Loader/Loader";
import toast from "react-hot-toast";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ManageFounder = () => {
    const [founder, setFounder] = useState([]);
    const [id, setId] = useState("");
    const [role, setRole] = useState("");
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);
    const[about,setAbout]=useState([{
        value:"",error:""
    }])
    const [showAddImage, setShowAddImage] = useState(0);
    const [showImage, setShowImage] = useState(null);
    const [error, setError] = useState({
        name: "",
        role: "",
        image: "",
      });
    useEffect(() => {
      getFounder();
    }, []);
  
    const getFounder = async () => {
      try {
        const response = await client.get("/founder/getFounder",{
          withCredentials:true
        });
        if (response.status === 200) {
          setFounder(response.data);
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          toast.error("Login again");
        } else {
          toast.error("Failed to fetch founder details");
        }
      }
    };
    const cancel = (e) => {
      message.error("Cancle delete");
    };
    const handleDelete = async (id) => {
      try {
        const response = await client.post('/founder/deletefounder', {
          id: id,
        },{
            withCredentials:true
        });
        if (response.status === 200) {
          setFounder(founder.filter((founder) => founder._id !== id));
        }
      } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 401) {
            toast.error("Login again");
          } else {
            toast.error("Failed to delete founder details");
          }
      }
    };


    
    const handleUpdate = (id, value) => {
        console.log(value)
        setOpen(true);
        setId(id);
        setRole(value.role);
        setName(value.name);
        setImage(value.image);
        const about =value.about.map((value)=>{
            return {
                value:value,
                error:""
            }
        })
       setAbout(about)
      };

console.log(about)
      const handleImageChange = (e) => {
        const image = e.target.files[0];
        if (image) {
          const fileType = image.type;
          const fileSize = image.size / 1024 / 1024; // Convert file size to MB
    
          if (!fileType.startsWith("image/")) {
            setError((prev) => {
              return { ...prev, image: "Please select a valid image file" };
            });
          } else if (fileSize > 1) {
            setError((prev) => {
              return { ...prev, image: "File size exceeds 1 MB" };
            });
          } else {
            var reader = new FileReader();
            reader.onload = function () {
              setImage(image);
              setShowImage(reader.result);
            };
            reader.readAsDataURL(image);
            setError((prev) => {
              return { ...prev, image: "" };
            });
          }
        }
      };
    
      const errorMessage = (fieldName, fieldValue) => {
        let message;
        if (fieldName) {
          if (fieldValue === "") {
            message = "";
          }
        }
    
        if (fieldName === "name") {
          if (fieldValue.length < 3) {
            message = `Name is Invalid`;
          } else {
            message = "";
          }
        }
        if (fieldName === "role") {
          if (fieldValue.length < 10) {
            message = `role is Invalid`;
          } else {
            message = "";
          }
        }
        return { message: message };
      };
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        const err = errorMessage(name, value).message;
    
        setError((prevError) => ({
          ...prevError,
          [name]: err,
        }));
        if (name === "name") {
          setName(value);
        } else {
          setRole(value);
        }
      };
    
      const handleBlur = (e) => {
        const { name, value } = e.target;
        if (value === "") {
          setError((prevError) => ({
            ...prevError,
            [name]: `${name} is required`,
          }));
        }
      };

      //about
      
  const handleAboutChange = (index, e) => {
    const { value } = e.target;
    const newAbout = [...about];
    newAbout[index].value = value;

    if (value.length < 10) {
      newAbout[index].error = "About content must be at least 10 characters.";
    } else {
      newAbout[index].error = "";
    }

    setAbout(newAbout);
  };

  // Handle About field blur (to show errors when losing focus)
  const handleAboutBlur = (index, e) => {
    const { value } = e.target;
    const newAbout = [...about];
    if (value === "") {
      newAbout[index].error = "About content is required.";
    }
    setAbout(newAbout);
  };
  const addAboutField = () => {
    if (about.length >= 5) {
      toast.error("Maximum of 5 About fields can be added.");
      return;
    }

    const lastField = about[about.length - 1];
    if (lastField.value === "") {
      toast.error("Complete the current field.");
    } else if (lastField.error) {
      toast.error("Fix the error in about field");
    } else {
      setAbout([...about, { value: "", error: "" }]);
    }
  };

  // Delete About content field
  const deleteAboutField = (index) => {
    if (about.length <= 1) {
      toast.error("At least one About field must remain.");
      return;
    }

    const newAbout = [...about];
    newAbout.splice(index, 1);
    setAbout(newAbout);
  };

    console.log(about)
      const updateFounder=()=>{
        const hasError = about.some(field => field.value === "" );
        if (name === "" || role === "" || image === null ||hasError ) {
            toast.error("All fields are required.");
          } else if (error.name !== "") {
            toast.error(error.name);
          } else if (error.role !== "") {
            toast.error(error.role);
          } else if (error.image !== "") {
            toast.error(error.image);
          } else if ( about.some(field =>  field.error !=="")){
            toast.error("Fix the error in about field");
          }else {
            setLoading(true);
            sendData();
          }
      }

      const sendData=async()=>{
        try{
        const formData = new FormData();
        formData.append("id",id)
        formData.append("image", image);
        formData.append("name", name);
        formData.append("role", role);
        about.forEach((field, index) => {
        formData.append("about",field.value);})
  
        const response = await client.post("/founder/updatefounder", formData, {
          withCredentials: true,
        });
        if (response.status === 200) {
          toast.success("Founders Added Successfully");
          getFounder()
          setOpen(false);
          setLoading(false);
          setId("")
          setRole("");
          setAbout([{
            value:"",error:""
          }])
          setImage(null);
          setShowAddImage(0);
          setShowImage(null)
          setName("");
          setError((pre) => {
            return { ...pre,  name: "",
                role: "",
                image: "", };
          });
        }
      } catch (err) {
        setLoading(false);
        if (err.response && err.response.status === 401) {
          toast.error("Login again");
        } else {
          toast.error("Failed to Update Founders details");
        }
      }
      }

  
  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Dashboard</h1>
        <nav
          style={{
            marginTop: "10px",
          }}
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Manage/Founder</li>
          </ol>
        </nav>
      </div>

      <div
        style={{
          padding: "20px",
          background: "white",
          boxShadow: "0 0 10px lightgray",
        }}
      >
        <h3>Manage Founder</h3>
        <div className="table-responsive">
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr
                style={{ borderBottom: "2px solid #ddd", textAlign: "center" }}
              >
                <th style={{ padding: "8px" }}>Image</th>
                <th style={{ padding: "8px" }}>Name</th>
              
                <th style={{ padding: "8px",width:"20%" }}>Role</th>
                <th style={{ padding: "8px" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {founder.length > 0 ? (
                founder.map((founder) => (
                  <tr
                    key={founder._id}
                    style={{ borderBottom: "1px solid #ddd" }}
                  >
                    <td style={{ padding: "8px" }}>
                      <img
                        src={founder.image}
                        alt={founder.name}
                        style={{ width: "100px", height: "100px" }}
                      />
                    </td>
                    <td style={{ padding: "8px" }}>{founder.name}</td>
                    <td style={{ padding: "8px" }}>{founder.role}</td>
                 
                    <td style={{ padding: "8px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdate(founder._id, founder)}
                         className="table-button"
                         style={{
                          marginRight:"5px"
                         }}
                    >
                      Update
                    </Button>
                      <Popconfirm
                        title="Delete the founder"
                        description="Are you sure to delete this founder?"
                        onConfirm={() => handleDelete(founder._id)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                        className="table-button"
                      >
                        <Button
                          variant="contained"
                          color="error"
                          className="table-button"
                        >
                          Delete
                        </Button>
                      </Popconfirm>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="5"
                    style={{ textAlign: "center", padding: "8px" }}
                  >
                    No founder available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div>
          <Modal
            show={open}
            onHide={() => {
              setOpen(false);
              setRole("");
              setId("");
              setAbout([{
                value:"",error:""
              }])
              setImage(null);
              setShowAddImage(0);
              setShowImage(null)
              setName("");
              setError((pre) => {
                return { ...pre,  name: "",
                    role: "",
                    image: "", };
              });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Founders</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <div className="">
                  <Form.Group>
                    <div className="">
                      <div>
                        <FormLabel>
                          <span>Name</span>
                        </FormLabel>
                      </div>
                      <div className="mt-3 p-3">
                      <TextField
                  label="Name"
                  slotProps={{
                    htmlInput: {
                      maxLength: 20,
                    },
                  }}
                 
                
              
                  fullWidth
                  variant="outlined"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  error={!!error.name}
                  helperText={error.name}
                  onBlur={handleBlur}
                  onKeyDown={(e) => {
                    const allowedKeys = [
                      "Backspace",
                      "ArrowLeft",
                      "ArrowRight",
                      "Delete",
                      "Tab",
                      " ",
                    ];
                    const allowedCharPattern = /^[A-Za-z.,_-]$/;
                    // Prevent spaces as the first character
                    if (name.length === 0 && e.key === " ") {
                      e.preventDefault();
                      return;
                    }
              
                    // Check if the pressed key is not allowed
                    if (
                      !allowedKeys.includes(e.key) &&
                      !allowedCharPattern.test(e.key)
                    ) {
                      e.preventDefault(); // Prevent the default action of the disallowed key
                    }
                  }}
                />
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </Form>
              <Form>
                <div className="">
                  <Form.Group>
                    <div className="">
                      <div>
                        <FormLabel>
                          <span>Role</span>
                        </FormLabel>
                      </div>
                      <div className="mt-3 p-3">
                      <TextField
                  label="role"
                  name="role"
                  multiline
                  slotProps={{
                    htmlInput: {
                      maxLength: 100,
                    },
                  }}
               
                
                  onKeyDown={(e) => {
                 
                    // Prevent spaces as the first character
                    if (role.length === 0 && e.key === " ") {
                      e.preventDefault();
                      return;
                    }
              
                  
                  }}
                  fullWidth
                  variant="outlined"
                  value={role}
                  onChange={handleChange}
                  error={!!error.role}
                  helperText={error.role}
                  onBlur={handleBlur}
                />
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </Form>
              <Form>
                <div>
                    <Form.Group>
                  
                      <div className="mt-3 p-3">
                      <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h5 style={{ textAlign: "center" }}>About Content</h5>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    onClick={addAboutField}
                  >
                    Add
                  </Button>
                </Box>
              </div>

              {about.map((field, index) => (
                <Box
                  key={index}
                  sx={{
                    marginBottom: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    label={`About Content ${index + 1}`}
                    fullWidth
                    variant="outlined"
                    slotProps={{
                      htmlInput: {
                        maxLength: 500,
                      },
                    }}
                    rows={2}
                    multiline
                    value={field.value}
                    onChange={(e) => handleAboutChange(index, e)}
                    onBlur={(e) => handleAboutBlur(index, e)}
                    error={!!field.error}
                    helperText={field.error}
                    onKeyDown={(e) => {
                      const allowedKeys = [
                          "Backspace",
                          "ArrowLeft",
                          "ArrowRight",
                          "Delete",
                          "Tab",
                          " ",
                      ];
                      const allowedCharPattern = /^[A-Za-z.,-_ ]$/; // Allow letters, spaces, and some special characters
      
                      // Prevent spaces as the first character
                      if (field.value.length === 0 && e.key === " ") {
                          e.preventDefault();
                          return;
                      }
      
                      // Check if the pressed key is not allowed
                      if (!allowedKeys.includes(e.key) && !allowedCharPattern.test(e.key)) {
                          e.preventDefault(); // Prevent the default action of the disallowed key
                      }
                  }}
      
                  />
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => deleteAboutField(index)}
                    disabled={about.length <= 1} // Disable for the last field
                    sx={{ marginLeft: "10px" }}
                  >
                    Delete
                  </Button>
                </Box>
              ))}
                      </div>
                    </Form.Group>
                </div>
              </Form>
              <Form>
                <div className="">
                  <Form.Group>
                  <div className="" style={{ width: "100%" }}>
                      <div>
                      <p>Image</p>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        {showAddImage === 1 ? (
                          <>
                            <Button
                              component="label"
                              role={undefined}
                              variant="contained"
                              tabIndex={-1}
                              startIcon={<CloudUploadIcon />}
                              style={{ marginTop: "10px" }}
                              disabled={image !== null}
                            >
                              change Image
                              <VisuallyHiddenInput
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                onClick={(e)=>{
                                  e.target.value = null;
                                }}
                              />
                            </Button>
                            <div>
                              {showImage === null ? (
                                <></>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                    marginTop: "10px",
                                  }}
                                >
                                  <img
                                    src={showImage}
                                    alt=""
                                    width="100px"
                                    height="100px"
                                  />
                                  <Button
                                    color="error"
                                    variant="contained"
                                    onClick={() => {
                                      setShowImage(null);
                                      setImage(null);
                                      setShowAddImage(1);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className="image-delete-button">
                            <img
                              src={image}
                              alt=" "
                              width="100px"
                              height="100px"
                            />
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => {
                                setShowAddImage(1);
                                setImage(null);
                                setShowImage(null);

                              }}
                              style={{
                                marginLeft: "10px",
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        )}
                        {error.image && (
                          <div
                            style={{
                              color: "red",
                              marginLeft: "20px",
                              fontSize: "13px",
                            }}
                          >
                            {error.image}
                          </div>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="contained"
                onClick={updateFounder}
                color="success"
              >
                Save changes
              </Button>
            </Modal.Footer>
            {loading && <Loader />}
          </Modal>
     
        </div>
      
    </main>
  )
}

export default ManageFounder;