import React, { useState, useEffect } from "react";
import client from "../../../Common/Client/Client";
import { Link } from "react-router-dom";
import { message, Popconfirm } from "antd";
import { Button, styled } from "@mui/material";
import { Modal, Form } from "react-bootstrap";
import { FormLabel } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Loader from "../../../Common/Loader/Loader";
import toast from "react-hot-toast";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const ManageReview = () => {
    const [reviews, setReviews] = useState([]);
 
  useEffect(() => {
    getReview();
  }, []);

  const getReview = async () => {
    try {
      const response = await client.get("/review/getreview",{
        withCredentials:true
      });
      if (response.status === 200) {
        setReviews(response.data);
        console.log(response);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to get review details");
      }
    }
  };
  const cancel = (e) => {
    message.error("Cancle delete");
  };
  const handleDelete = async (id) => {
    try {
      const response = await client.post('/review/deletereview', {
        id: id,
      },{
        withCredentials:true
      });
      if (response.status === 200) {
        setReviews(reviews.filter((reviews) => reviews._id !== id));
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to delete review details");
      }
    }
  };



 

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Dashboard</h1>
        <nav
          style={{
            marginTop: "10px",
          }}
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Manage/Reviews</li>
          </ol>
        </nav>
      </div>

      <div
        style={{
          padding: "20px",
          background: "white",
          boxShadow: "0 0 10px lightgray",
        }}
      >
        <h3>Manage Review</h3>
        <div className="table-responsive">
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr
                style={{ borderBottom: "2px solid #ddd", textAlign: "center" }}
              >
                <th style={{ padding: "8px" }}>Image</th>
                <th style={{ padding: "8px" }}>Name</th>
                <th style={{ padding: "8px" }}>Review</th>
                <th style={{ padding: "8px" }}>Rating</th>
                <th style={{ padding: "8px" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {reviews.length > 0 ? (
                reviews.map((review) => (
                  <tr
                    key={review._id}
                    style={{ borderBottom: "1px solid #ddd" }}
                  >
                    <td style={{ padding: "8px" }}>
                      <img
                        src={review.image}
                        alt={review.name}
                        style={{ width: "100px", height: "100px" }}
                      />
                    </td>
                    <td style={{ padding: "8px" }}>{review.name}</td>
                    <td style={{ padding: "8px" }}>{review.review}</td>
                    <td style={{ padding: "8px" }}>{review.rating}</td>
                    <td style={{ padding: "8px" }}>
                      <Popconfirm
                        title="Delete the Review"
                        description="Are you sure to delete this review?"
                        onConfirm={() => handleDelete(review._id)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                        className="table-button"
                      >
                        <Button
                          variant="contained"
                          color="error"
                          className="table-button"
                        >
                          Delete
                        </Button>
                      </Popconfirm>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="5"
                    style={{ textAlign: "center", padding: "8px" }}
                  >
                    No reviews available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </main>
  );
};

export default ManageReview;
