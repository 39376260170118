import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { TextField } from "@mui/material";
import Button from "@mui/material/Button";
import toast from "react-hot-toast";
import Grid from "@mui/material/Grid2";
import Loader from "../../Common/Loader/Loader";
import client from "../../Common/Client/Client";

const Contact = () => {
  const [contactInfo, setContactInfo] = useState({
    id: "",
    phoneIndia: "",
    phoneUAE: "",
    whatsapp: "",
    email: "",
    youtube: "",
    facebook: "",
    tiktok: "",
    instagram: "",
    address:""
  });

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    phoneIndia: "",
    phoneUAE: "",
    whatsapp: "",
    email: "",
    youtube: "",
    facebook: "",
    tiktok: "",
    instagram: "",
    address:""
  });

  useEffect(() => {
    fecthContact();
  }, []);

  const fecthContact = async () => {
    try {
      const response = await client.get("/contact/get");
      if (response.status === 200) {
        const data = response.data[0];
        console.log(data);
        const removeCountryCode = (data) => {
          return {
            ...data,
            id: data._id,
            email: data.email,
            address:data.address,
            youtube: data.youtube,
            facebook: data.facebook,
            tiktok: data.tiktok,
            instagram: data.instagram,
            phoneIndia: data.phoneIndia.replace(/^\+91\s*/, ""),
            phoneUAE: data.phoneUAE.replace(/^\+971\s*/, ""),
            whatsapp: data.whatsapp.replace(/^\+971\s*/, ""),
          };
        };

        const updatedContactInfo = removeCountryCode(data);
        console.log(updatedContactInfo);
        setContactInfo(updatedContactInfo);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to get contact details");
      }
    }
  };
  const errorMessage = (fieldName, fieldValue) => {
    let message;
    if (fieldName) {
      if (fieldValue === "") {
        message = "";
      }
    }

    if(fieldName==="address"){
      if(fieldValue.length<10){
        message="Please enter a valid address"
        }else{
          message="";
        }

    }
    if (fieldName === "phoneIndia") {
      // Remove non-numeric characters for validation
      const numericValue = fieldValue.replace(/[^0-9]/g, "");

      if (numericValue.length < 10) {
        message = "Phone number needs 10 characters";
      } else if (numericValue.length > 10) {
        message = "Phone number is too long";
      } else {
        const prefix = parseInt(numericValue.slice(0, 2), 10);
        if (!(prefix >= 63 && prefix <= 99)) {
          message = "Invalid Phone Number";
        } else {
          message = "";
        }
      }
    }

    if (fieldName === "phoneUAE") {
      if (fieldValue.length < 9) {
        message = "Phone number needs 9 characters";
      } else {
        message = "";
      }
    }

    if (fieldName === "whatsapp") {
      if (fieldValue.length < 9) {
        message = "Whatsapp number needs 9 characters";
      } else {
        message = "";
      }
    }

    if (fieldName === "email") {
      const emailRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]{2,}@[a-zA-Z-]+\.[a-zA-Z-]{2,}$/;
      if (!emailRegex.test(fieldValue)) {
        message = `Email is Invalid`;
      } else {
        message = "";
      }
    }

    if (fieldName === "youtube") {
      const youtubeRegex =
        /^(https?:\/\/)?(www\.)?(youtube\.com\/(channel\/|c\/|user\/|@)).+$/;
      if (!youtubeRegex.test(fieldValue)) {
        message = `Please enter a valid YouTube URL`;
      } else {
        message = "";
      }
    }

    if (fieldName === "facebook") {
      const facebookProfileRegex =
        /^(https?:\/\/)?(www\.)?(facebook\.com\/).+$/;
      if (!facebookProfileRegex.test(fieldValue)) {
        message = `Please enter a valid Facebook profile URL`;
      } else {
        message = "";
      }
    }
    if (fieldName === "tiktok") {
      const tiktokProfileRegex = /^(https?:\/\/)?(www\.)?(tiktok\.com\/@).+$/;
      if (!tiktokProfileRegex.test(fieldValue)) {
        message = `Please enter a valid TikTok profile URL`;
      } else {
        message = "";
      }
    }

    if (fieldName === "instagram") {
      const instagramProfileRegex =
        /^(https?:\/\/)?(www\.)?(instagram\.com\/).+$/;
      if (!instagramProfileRegex.test(fieldValue)) {
        message = `Please enter a valid Instagram profile URL`;
      } else {
        message = "";
      }
    }
    return { message: message };
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setError((prevError) => ({
        ...prevError,
        [name]: `${name} is required`,
      }));
    }
  };
  const handleChange = (e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;

    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    setContactInfo({
      ...contactInfo,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async () => {
    if (
      contactInfo.email === "" ||
      contactInfo.facebook === "" ||
      contactInfo.instagram === "" ||
      contactInfo.phoneIndia === "" ||
      contactInfo.phoneUAE === "" ||
      contactInfo.tiktok === "" ||
      contactInfo.whatsapp === "" ||
      contactInfo.youtube === ""||
      contactInfo.address===""
    ) {
      toast.error("Please enter all input field in the  form.");
      return;
    } else if (
      error.email !== "" ||
      error.facebook !== "" ||
      error.instagram !== "" ||
      error.phoneIndia !== "" ||
      error.phoneUAE !== "" ||
      error.tiktok !== "" ||
      error.whatsapp !== "" ||
      error.youtube !== ""||
      error.address!==""
    ) {
      toast.error("Please correct the errors in the form.");
    } else {
      postData();
    }
  };

  const postData = async () => {
    const updatedContactInfo = {
      ...contactInfo,
      phoneIndia: `+91 ${contactInfo.phoneIndia.replace(/^\+91\s*/, "")}`,
      phoneUAE: `+971 ${contactInfo.phoneUAE.replace(/^\+971\s*/, "")}`,
      whatsapp: `+971 ${contactInfo.whatsapp.replace(/^\+971\s*/, "")}`,
    };
    setContactInfo(updatedContactInfo);
    setLoading(true);
    try {
      console.log(contactInfo);
      const response = await client.post("/contact/save", updatedContactInfo,{withCredentials:true});

      if (response.status === 200) {
        toast.success("Contact information updated successfully");
        fecthContact();
        setLoading(false);
      }
    } catch (err) {
     
      setLoading(false);
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to get contact details");
      }
    }
  };
  return (
    <Fragment>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Dashboard</h1>
          <nav style={{ marginTop: "10px" }}>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active">Manage/Contact Details</li>
            </ol>
          </nav>
        </div>

        <section
          className="section dashboard"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "70px",
          }}
        >
          <div className="card" style={{ width: "100%", maxWidth: "1000px" }}>
            <div className="card-body" style={{ padding: "20px" }}>
              <h5
                className="card-title"
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                update Contact Details
              </h5>

              <Grid container spacing={2}>
                {/* India Phone and UAE Phone on the same line */}
                <Grid
                  item
                  size={{
                    xs: 12,
                    sm: 6,
                  }}
                >
                  <TextField
                    fullWidth
                    label="India Phone Number"
                    name="phoneIndia"
                    value={contactInfo.phoneIndia}
                    onKeyDown={(e) => {
                      const allowedKeys = [
                        "Backspace",
                        "ArrowLeft",
                        "ArrowRight",
                        "Delete",
                        "Tab",
                      ];
                      const allowedCharPattern = /^[0-9+]$/;

                      // Check if the pressed key is not allowed
                      if (
                        !allowedKeys.includes(e.key) &&
                        !allowedCharPattern.test(e.key)
                      ) {
                        e.preventDefault(); // Prevent the default action of the disallowed key
                      }
                    }}
                    onChange={handleChange}
                    error={!!error.phoneIndia}
                    onBlur={handleBlur}
                    helperText={error.phoneIndia}
                    variant="outlined"
                    margin="normal"
                    slotProps={{
                      htmlInput: {
                        maxLength: 10,
                      },
                    }}
                  />
                </Grid>

                <Grid
                  item
                  size={{
                    xs: 12,
                    sm: 6,
                  }}
                >
                  <TextField
                    fullWidth
                    label="UAE Phone Number"
                    name="phoneUAE"
                    value={contactInfo.phoneUAE}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!error.phoneUAE}
                    helperText={error.phoneUAE}
                    variant="outlined"
                    margin="normal"
                    onKeyDown={(e) => {
                      const allowedKeys = [
                        "Backspace",
                        "ArrowLeft",
                        "ArrowRight",
                        "Delete",
                        "Tab",
                      ];
                      const allowedCharPattern = /^[0-9+]$/;

                      // Check if the pressed key is not allowed
                      if (
                        !allowedKeys.includes(e.key) &&
                        !allowedCharPattern.test(e.key)
                      ) {
                        e.preventDefault(); // Prevent the default action of the disallowed key
                      }
                    }}
                    slotProps={{
                      htmlInput: {
                        maxLength: 9,
                      },
                    }}
                  />
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                {/* WhatsApp and Email on the same line */}
                <Grid
                  item
                  size={{
                    xs: 12,
                    sm: 6,
                  }}
                >
                  <TextField
                    label="WhatsApp Number"
                    fullWidth
                    name="whatsapp"
                    value={contactInfo.whatsapp}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={!!error.whatsapp}
                    helperText={error.whatsapp}
                    onKeyDown={(e) => {
                      const allowedKeys = [
                        "Backspace",
                        "ArrowLeft",
                        "ArrowRight",
                        "Delete",
                        "Tab",
                      ];
                      const allowedCharPattern = /^[0-9+]$/;

                      // Check if the pressed key is not allowed
                      if (
                        !allowedKeys.includes(e.key) &&
                        !allowedCharPattern.test(e.key)
                      ) {
                        e.preventDefault(); // Prevent the default action of the disallowed key
                      }
                    }}
                    variant="outlined"
                    margin="normal"
                    slotProps={{
                      htmlInput: {
                        maxLength: 9,
                      },
                    }}
                  />
                </Grid>

                <Grid
                  item
                  size={{
                    xs: 12,
                    sm: 6,
                  }}
                >
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    value={contactInfo.email}
                    onChange={handleChange}
                    error={!!error.email}
                    helperText={error.email}
                    onKeyDown={(event) => {
                      if (event.key === " ") {
                        event.preventDefault(); // Prevent space from being entered
                      }
                    }}
                    onBlur={handleBlur}
                    variant="outlined"
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {/* YouTube Link */}
                <Grid
                  item
                  size={{
                    xs: 12,
                    sm: 6,
                  }}
                >
                  <TextField
                    fullWidth
                    label="YouTube"
                    name="youtube"
                    value={contactInfo.youtube}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    onKeyDown={(event) => {
                      if (event.key === " ") {
                        event.preventDefault(); // Prevent space from being entered
                      }
                    }}
                    margin="normal"
                    error={!!error.youtube}
                    helperText={error.youtube}
                  />
                </Grid>

                {/* Facebook Link */}
                <Grid
                  item
                  size={{
                    xs: 12,
                    sm: 6,
                  }}
                >
                  <TextField
                    fullWidth
                    label="Facebook"
                    error={!!error.facebook}
                    helperText={error.facebook}
                    onKeyDown={(event) => {
                      if (event.key === " ") {
                        event.preventDefault(); // Prevent space from being entered
                      }
                    }}
                    name="facebook"
                    value={contactInfo.facebook}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {/* TikTok Link */}
                <Grid
                  item
                  size={{
                    xs: 12,
                    sm: 6,
                  }}
                >
                  <TextField
                    fullWidth
                    label="TikTok"
                    name="tiktok"
                    value={contactInfo.tiktok}
                    onKeyDown={(event) => {
                      if (event.key === " ") {
                        event.preventDefault(); // Prevent space from being entered
                      }
                    }}
                    error={!!error.tiktok}
                    helperText={error.tiktok}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    margin="normal"
                  />
                </Grid>

                {/* Instagram Username */}
                <Grid
                  item
                  size={{
                    xs: 12,
                    sm: 6,
                  }}
                >
                  <TextField
                    label="Instagram"
                    fullWidth
                    name="instagram"
                    error={!!error.instagram}
                    helperText={error.instagram}
                    value={contactInfo.instagram}
                    onChange={handleChange}
                    onKeyDown={(event) => {
                      if (event.key === " ") {
                        event.preventDefault(); // Prevent space from being entered
                      }
                    }}
                    onBlur={handleBlur}
                    variant="outlined"
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid
                  item
                  size={{
                    xs: 12,
                  }}
                >
                  <TextField
                    fullWidth
                    label="Adress"
                    name="address"
                    value={contactInfo.address}
                  
                    error={!!error.address}
                    helperText={error.address}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    variant="outlined"
                    margin="normal"
                    slotProps={{
                      htmlInput: {
                        maxLength: 300,
                      },
                    }}
                    onKeyDown={(e) => {
                     
                
                      // Prevent spaces as the first character
                      if (contactInfo.address.length === 0 && e.key === " ") {
                        e.preventDefault();
                        return;
                      }
                
                      // Check if the pressed key is not allowed
                    
                    }}
                  />
                </Grid>
              </Grid>

              <Button
                variant="contained"
                color="primary"
                style={{ display: "block", margin: "20px auto" }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </div>
        </section>
      </main>
      {loading && <Loader />}
    </Fragment>
  );
};

export default Contact;
