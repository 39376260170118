import React from 'react'
import { useLocation } from "react-router-dom";
import Header from '../Layout/Header/Header';

const HeaderAllow = (props) => {
    const {setAdmin}=props;
  const location=useLocation()
  const headerPaths = [
    "/", 
    "/update-password",
    "/gallery/videos",
    "/gallery/images",
    "/manage/images",
    "/manage/videos",
    "/reviews",
    "/manage/review",
    "/course",
    "/blogs",
    "/manage/contact",
    "/manage/register",
    "/manage/enqiure", 
    "/manage/blogs",
    "/manage/course",
    "/quotes",
    "/manage/quotes",
    "/founder",
    "/manage/founder",
    "/manage/about"
  ];
  return (
   <>
     {headerPaths.includes(location.pathname) && <Header setAdmin={setAdmin} />}
   </>
  )
}

export default HeaderAllow