import React, { Fragment, useState, useEffect } from "react";
import "./Home.css";
import { Link } from "react-router-dom";
import client from "../../Common/Client/Client";
import toast from "react-hot-toast";

export default function Home() {
  const [reviews, setReviews] = useState([]);
  const [enqiure, setEnqiure] = useState([]);
  const [register, setRegister] = useState([]);
  const [blogs, setBlogs] = useState([]);
  const [course, setCourse] = useState([]);
  const [urls, setUrls] = useState([]);

  useEffect(() => {
    fetchCourse();
    getReview();
    fetchEnqiure();
    fetchRegister();
    getBlogs();
    getImage();
  }, []);
  const getEmbedUrl = (videoUrl) => {
    console.log(videoUrl);
    if (typeof videoUrl !== "string") {
      return ""; // Return an empty string if videoUrl is not a string
    }

    const videoIdMatch = videoUrl.match(
      /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:embed\/|v\/|watch\?v=)|youtu\.be\/)([^"&?\/\s]{11})/
    );
    if (videoIdMatch) {
      const videoId = videoIdMatch[1];
      return `https://www.youtube.com/embed/${videoId}`;
    }

    return ""; // Return an empty string if no video ID is found
  };
  const getImage = async () => {
    try {
      const response = await client.get("/gallery/getyoutubeurl");
      if (response.status === 200) {
        setUrls(response.data.slice(-3));
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchCourse = async () => {
    try {
      const response = await client.get("/course/getcourse");
      if (response.status === 200) {
        setCourse(response.data.slice(-3)); // Get the last 3 courses
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchRegister = async () => {
    try {
      const response = await client.get("/register/get",{
        withCredentials:true
      });
      if (response.status === 200) {
        setRegister(response.data.slice(-5)); // Get the last 5 register entries
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to fetch register details");
      }
    }
  };

  const getBlogs = async () => {
    try {
      const response = await client.get("/blog/get");
      if (response.status === 200) {
        setBlogs(response.data.slice(-3)); // Get the last 3 blogs
      } else {
        toast.error("Error occuring in fetching data");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const fetchEnqiure = async () => {
    try {
      const response = await client.get("/user/get",{
        withCredentials:true
      });
      if (response.status === 200) {
        setEnqiure(response.data.slice(-5)); // Get the last 5 enquiries
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to fetch enqiure details");
      }
    }
  };

  const getReview = async () => {
    try {
      const response = await client.get("/review/getreview");
      if (response.status === 200) {
        setReviews(response.data.slice(-2)); // Get the last 2 reviews
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Fragment>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Dashboard</h1>
          <nav>
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active">Dashboard</li>
            </ol>
          </nav>
        </div>
        <section className="section dashboard">
          <div className="row">
            <div className="col-lg-8">
              <div className="row">
              <div className="col-xxl-4 col-md-6 mb-4">
  <div className="info-box blogs-info d-flex flex-column">
    <div className="info-box-body d-flex flex-column">
      <div className="info-icon d-flex justify-content-center">
        <i className="bi bi-file-earmark-text" />
      </div>
      <h5 className="info-title mt-3">
        Recent Blogs 
      </h5>
      <div className="align-items-center mt-auto">
        <div className="ps-3">
          <h6 style={{
            textAlign:"center"
          }}>{blogs.length}</h6>
          <span className="text-success small pt-1 fw-bold">
            {blogs.length > 0 ? "Updated" : "No New"}
          </span>
          <span className="text-muted small pt-2 ps-1">
            blogs this month
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div className="col-xxl-4 col-md-6 mb-4">
  <div className="info-box users-info d-flex flex-column">
    <div className="info-box-body d-flex flex-column">
      <div className="info-icon d-flex justify-content-center">
        <i className="bi bi-person-lines-fill" />
      </div>
      <h5 className="info-title mt-3">
        Registered Users
      </h5>
      <div className=" align-items-center mt-auto">
        <div className="ps-3">
          <h6>{register.length}</h6>
          <span className="text-success small pt-1 fw-bold">
            {register.length > 0 ? "New Registrations" : "No New"}
          </span>
          <span className="text-muted small pt-2 ps-1">
            this month
          </span>
        </div>
      </div>
    </div>
  </div>
</div>

<div className="col-xxl-4 col-xl-12 mb-4">
  <div className="info-box enquiries-info d-flex flex-column">
    <div className="info-box-body d-flex flex-column">
      <div className="info-icon d-flex justify-content-center">
        <i className="bi bi-envelope-open" />
      </div>
      <h5 className="info-title mt-3">
        User Enquiries 
      </h5>
      <div className=" align-items-center mt-auto">
        <div className="ps-3">
          <h6>{enqiure.length}</h6>
          <span className="text-success small pt-1 fw-bold">
            {enqiure.length > 0 ? "Recent Enquiries" : "No New"}
          </span>
          <span className="text-muted small pt-2 ps-1">
            received this month
          </span>
        </div>
      </div>
    </div>
  </div>
</div>



                <div className="col-12 mb-4">
                  <div className="card recent-blogs overflow-auto">
                    <div className="card-body">
                      <h5 className="card-title">
                        Recent Blogs <span>| Latest 3</span>
                      </h5>
                      <table className="table table-borderless datatable">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">Name</th>
                            <th scope="col">Image</th>
                            <th scope="col">Location</th>
                            <th scope="col">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {blogs.slice(-3).map((blog, index) => (
                            <tr key={blog.id}>
                              <th scope="row">{index + 1}</th>
                              <td>{blog.name}</td>
                              <td>
                                <img
                                  src={blog.image}
                                  alt={blog.name}
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    objectFit: "cover",
                                  }}
                                />
                              </td>
                              <td>{blog.location}</td>
                              <td>
                                {new Date(blog.date).toLocaleDateString()}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="col-12 mb-4">
                  <div className="card recent-registrations overflow-auto">
                    <div className="card-body">
                      <h5 className="card-title">
                        Registered Users <span>| Recent</span>
                      </h5>
                      <table className="table table-borderless datatable">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Course</th>
                          </tr>
                        </thead>
                        <tbody>
                          {register.slice(-5).map((user, index) => (
                            <tr key={user.id}>
                              <th scope="row">{index + 1}</th>
                              <td>{user.firstName}</td>
                              <td>{user.lastName}</td>
                              <td>{user.email}</td>
                              <td>{user.phone}</td>
                              <td>{user.course}</td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>

                <div className="col-12 mb-4">
                  <div className="card recent-enquiries overflow-auto">
                    <div className="card-body">
                      <h5 className="card-title">
                        User Enquiries <span>| Latest</span>
                      </h5>
                      <table className="table table-borderless datatable">
                        <thead>
                          <tr>
                            <th scope="col">S.No</th>
                            <th scope="col">First Name</th>
                            <th scope="col">Last Name</th>
                            <th scope="col">Email</th>
                            <th scope="col">Phone</th>
                            <th scope="col">Message</th>
                          </tr>
                        </thead>
                        <tbody>
                          {enqiure.slice(-5).map((enquiry, index) => (
                            <tr key={enquiry.id}>
                              <th scope="row">{index + 1}</th>
                              <td>{enquiry.firstName}</td>
                              <td>{enquiry.lastName}</td>
                              <td>{enquiry.email}</td>
                              <td>{enquiry.phoneNumber}</td>
                              <td>
                                {enquiry.message
                                  .split(" ")
                                  .slice(0, 20)
                                  .join(" ")}
                                ...
                              </td>{" "}
                              {/* Limit message to 20 words */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title">
                    Recent Reviews <span>| Latest</span>
                  </h5>
                  <div className="activity">
                    {reviews.slice(-2).map((review, index) => (
                      <div
                        className="activity-item pb-3 mb-3"
                        style={{
                          borderBottom: "1px solid #e0e0e0",
                        }}
                        key={index}
                      >
                        <div className="activity-content">
                          <div className="d-flex justify-content-between align-items-center">
                            <div
                              className="d-flex align-items-center justify-content-between"
                              style={{ width: "100%" }}
                            >
                              {/* Name on the left */}

                              {/* Image on the right */}
                              <div
                                className="review-image"
                                style={{
                                  marginLeft: "10px",
                                  display: "flex",
                                  justifyContent: "flex-start",
                                }}
                              >
                                <img
                                  src={review.image}
                                  alt={review.name}
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                  }}
                                  className="img-fluid"
                                />
                              </div>
                              <div style={{ fontWeight: "bold" }}>
                                {review.name}
                              </div>
                            </div>
                          </div>

                          {/* Review Text */}
                          <div className="mt-2">
                            <p
                              className="text-muted mb-0"
                              style={{ fontSize: "0.9rem" }}
                            >
                              {review.review}
                            </p>
                          </div>

                          {/* Rating Centered */}
                          <div className="text-center mt-2">
                            <span className="badge bg-info">
                              Rating: {review.rating}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-body pb-0">
                  <h5 className="card-title">
                    Latest YouTube Videos <span>| Recent</span>
                  </h5>

                  <div className="row">
                    {urls.slice(-2).map((item, index) => (
                      <div
                        key={index}
                        className="col-12 mb-sm-4"
                        style={{
                          borderBottom: "2px solid #e0e0e0", // Adds bottom border
                          paddingBottom: "15px", // Adds some space between the content and the border
                          marginBottom: "15px", // Adds space between cards
                        }}
                      >
                        <div className="video-container">
                          <iframe
                            width="100%" // Full width
                            height="200" // Increase height for full view
                            src={getEmbedUrl(item.videoUrl)}
                            title={`YouTube video ${index}`}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                          ></iframe>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short" />
      </a>
    </Fragment>
  );
}
