import React, { useState, useEffect } from "react";
import client from "../../../Common/Client/Client";
import { Link } from "react-router-dom";
import { message, Popconfirm } from "antd";
import { Button, styled,TextField } from "@mui/material";
import { Modal, Form } from "react-bootstrap";
import { FormLabel } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Loader from "../../../Common/Loader/Loader";
import toast from "react-hot-toast";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const ManageQuotes = () => {
    const [quotes, setQuotes] = useState([]);
    const [id, setId] = useState("");
    const [dataQuotes, setDataQuotes] = useState("");
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(null);
    const [showAddImage, setShowAddImage] = useState(0);
    const [showImage, setShowImage] = useState(null);
    const [error, setError] = useState({
        name: "",
        quotes: "",
        image: "",
      });
    useEffect(() => {
      getQuotes();
    }, []);
  
    const getQuotes = async () => {
      try {
        const response = await client.get("/quotes/getquotes",{
          withCredentials:true
        });
        if (response.status === 200) {
          setQuotes(response.data);
        }
      } catch (err) {
        if (err.response && err.response.status === 401) {
          toast.error("Login again");
        } else {
          toast.error("Failed to fetch quotes details");
        }
      }
    };
    const cancel = (e) => {
      message.error("Cancle delete");
    };
    const handleDelete = async (id) => {
      try {
        const response = await client.post('/quotes/deletequotes', {
          id: id,
        },{
            withCredentials:true
        });
        if (response.status === 200) {
          setQuotes(quotes.filter((quotes) => quotes._id !== id));
        }
      } catch (err) {
        console.log(err);
        if (err.response && err.response.status === 401) {
            toast.error("Login again");
          } else {
            toast.error("Failed to delete quotes details");
          }
      }
    };

    //update

    const handleUpdate = (id, value) => {
        setOpen(true);
        setId(id);
        setDataQuotes(value.quotes);
        setName(value.name)
        setImage(value.image)
      };


      const handleImageChange = (e) => {
        const image = e.target.files[0];
        if (image) {
          const fileType = image.type;
          const fileSize = image.size / 1024 / 1024; // Convert file size to MB
    
          if (!fileType.startsWith("image/")) {
            setError((prev) => {
              return { ...prev, image: "Please select a valid image file" };
            });
          } else if (fileSize > 1) {
            setError((prev) => {
              return { ...prev, image: "File size exceeds 1 MB" };
            });
          } else {
            var reader = new FileReader();
            reader.onload = function () {
              setImage(image);
              setShowImage(reader.result);
            };
            reader.readAsDataURL(image);
            setError((prev) => {
              return { ...prev, image: "" };
            });
          }
        }
      };
    
      const errorMessage = (fieldName, fieldValue) => {
        let message;
        if (fieldName) {
          if (fieldValue === "") {
            message = "";
          }
        }
    
        if (fieldName === "name") {
          if (fieldValue.length < 3) {
            message = `Name is Invalid`;
          } else {
            message = "";
          }
        }
        if (fieldName === "quotes") {
          if (fieldValue.length < 10) {
            message = `Quotes is Invalid`;
          } else {
            message = "";
          }
        }
        return { message: message };
      };
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        const err = errorMessage(name, value).message;
    
        setError((prevError) => ({
          ...prevError,
          [name]: err,
        }));
        if (name === "name") {
          setName(value);
        } else {
          setDataQuotes(value);
        }
      };
    
      const handleBlur = (e) => {
        const { name, value } = e.target;
        if (value === "") {
          setError((prevError) => ({
            ...prevError,
            [name]: `${name} is required`,
          }));
        }
      };
    
      const updateQuotes=()=>{
        if (name === "" || dataQuotes === "" || image === null ) {
            toast.error("All fields are required.");
          } else if (error.name !== "") {
            toast.error(error.name);
          } else if (error.quotes !== "") {
            toast.error(error.quotes);
          } else if (error.image !== "") {
            toast.error(error.image);
          } else {
            setLoading(true);
            sendData();
          }
      }

      const sendData=async()=>{
        try {
            const formData = new FormData();
            formData.append("image", image);
            formData.append("name", name);
            formData.append("quotes", dataQuotes);
            formData.append("id",id)
            const response = await client.post("/quotes/updatequotes", formData,{
              withCredentials:true
            });
            if (response.status === 200) {
                getQuotes()
              toast.success("quotes updated Successfully");
              setOpen(false);
              setImage(null);
              setShowAddImage(0);
              setName("");
              setDataQuotes("");
              setImage(null);
              setId("");
              setLoading(false);
            }
        }catch(err){
            console.log(err);
            if (err.response && err.response.status === 401) {
                toast.error("Login again");
                setLoading(false);
              } else {
                setLoading(false);
                toast.error("Failed to update quotes details");
              }
        }
      }
  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Dashboard</h1>
        <nav
          style={{
            marginTop: "10px",
          }}
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Manage/quotes</li>
          </ol>
        </nav>
      </div>

      <div
        style={{
          padding: "20px",
          background: "white",
          boxShadow: "0 0 10px lightgray",
        }}
      >
        <h3>Manage Quotes</h3>
        <div className="table-responsive">
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr
                style={{ borderBottom: "2px solid #ddd", textAlign: "center" }}
              >
                <th style={{ padding: "8px" }}>Image</th>
                <th style={{ padding: "8px" }}>Name</th>
              
                <th style={{ padding: "8px",width:"20%" }}>Quotes</th>
                <th style={{ padding: "8px" }}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {quotes.length > 0 ? (
                quotes.map((quotes) => (
                  <tr
                    key={quotes._id}
                    style={{ borderBottom: "1px solid #ddd" }}
                  >
                    <td style={{ padding: "8px" }}>
                      <img
                        src={quotes.image}
                        alt={quotes.name}
                        style={{ width: "100px", height: "100px" }}
                      />
                    </td>
                    <td style={{ padding: "8px" }}>{quotes.name}</td>
                    <td style={{ padding: "8px" }}>{quotes.quotes}</td>
                 
                    <td style={{ padding: "8px" }}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdate(quotes._id, quotes)}
                         className="table-button"
                         style={{
                          marginRight:"5px"
                         }}
                    >
                      Update
                    </Button>
                      <Popconfirm
                        title="Delete the quotes"
                        description="Are you sure to delete this quotes?"
                        onConfirm={() => handleDelete(quotes._id)}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                        className="table-button"
                      >
                        <Button
                          variant="contained"
                          color="error"
                          className="table-button"
                        >
                          Delete
                        </Button>
                      </Popconfirm>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan="5"
                    style={{ textAlign: "center", padding: "8px" }}
                  >
                    No quotes available
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <div>
          <Modal
            show={open}
            onHide={() => {
              setOpen(false);
              setDataQuotes("");
              setImage(null);
              setShowAddImage(0);
              setShowImage(null)
              setName("");
              setError((pre) => {
                return { ...pre,  name: "",
                    quotes: "",
                    image: "", };
              });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>Update Quotes</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <div className="">
                  <Form.Group>
                    <div className="">
                      <div>
                        <FormLabel>
                          <span>Name</span>
                        </FormLabel>
                      </div>
                      <div className="mt-3 p-3">
                      <TextField
                  label="Name"
                  slotProps={{
                    htmlInput: {
                      maxLength: 20,
                    },
                  }}
                 
                
              
                  fullWidth
                  variant="outlined"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  error={!!error.name}
                  helperText={error.name}
                  onBlur={handleBlur}
                  onKeyDown={(e) => {
                    const allowedKeys = [
                      "Backspace",
                      "ArrowLeft",
                      "ArrowRight",
                      "Delete",
                      "Tab",
                      " ",
                    ];
                    const allowedCharPattern = /^[A-Za-z.,_-]$/;
                    // Prevent spaces as the first character
                    if (name.length === 0 && e.key === " ") {
                      e.preventDefault();
                      return;
                    }
              
                    // Check if the pressed key is not allowed
                    if (
                      !allowedKeys.includes(e.key) &&
                      !allowedCharPattern.test(e.key)
                    ) {
                      e.preventDefault(); // Prevent the default action of the disallowed key
                    }
                  }}
                />
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </Form>
              <Form>
                <div className="">
                  <Form.Group>
                    <div className="">
                      <div>
                        <FormLabel>
                          <span>Qutes</span>
                        </FormLabel>
                      </div>
                      <div className="mt-3 p-3">
                      <TextField
                  label="quotes"
                  name="quotes"
                  multiline
                  rows={4}
                  slotProps={{
                    htmlInput: {
                      maxLength: 250,
                    },
                  }}
               
                
                  onKeyDown={(e) => {
                 
                    // Prevent spaces as the first character
                    if (quotes.length === 0 && e.key === " ") {
                      e.preventDefault();
                      return;
                    }
              
                  
                  }}
                  fullWidth
                  variant="outlined"
                  value={dataQuotes}
                  onChange={handleChange}
                  error={!!error.quotes}
                  helperText={error.quotes}
                  onBlur={handleBlur}
                />
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </Form>
              <Form>
                <div className="">
                  <Form.Group>
                  <div className="" style={{ width: "100%" }}>
                      <div>
                      <p>Image</p>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        {showAddImage === 1 ? (
                          <>
                            <Button
                              component="label"
                              role={undefined}
                              variant="contained"
                              tabIndex={-1}
                              startIcon={<CloudUploadIcon />}
                              style={{ marginTop: "10px" }}
                              disabled={image !== null}
                            >
                              change Image
                              <VisuallyHiddenInput
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                onClick={(e)=>{
                                  e.target.value = null;
                                }}
                              />
                            </Button>
                            <div>
                              {showImage === null ? (
                                <></>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                    marginTop: "10px",
                                  }}
                                >
                                  <img
                                    src={showImage}
                                    alt=""
                                    width="100px"
                                    height="100px"
                                  />
                                  <Button
                                    color="error"
                                    variant="contained"
                                    onClick={() => {
                                      setShowImage(null);
                                      setImage(null);
                                      setShowAddImage(1);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className="image-delete-button">
                            <img
                              src={image}
                              alt=" "
                              width="100px"
                              height="100px"
                            />
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => {
                                setShowAddImage(1);
                                setImage(null);
                                setShowImage(null);

                              }}
                              style={{
                                marginLeft: "10px",
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        )}
                        {error.image && (
                          <div
                            style={{
                              color: "red",
                              marginLeft: "20px",
                              fontSize: "13px",
                            }}
                          >
                            {error.image}
                          </div>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="contained"
                onClick={updateQuotes}
                color="success"
              >
                Save changes
              </Button>
            </Modal.Footer>
            {loading && <Loader />}
          </Modal>
     
        </div>
      
    </main>
  )
}

export default ManageQuotes