import React,{Fragment,useState} from 'react'
import { Link } from "react-router-dom";
import { FormLabel } from "@mui/material";
import Button from "@mui/material/Button";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import toast from "react-hot-toast";
import './Image.css'
import client from '../../../Common/Client/Client';
import Loader from '../../../Common/Loader/Loader';
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const Images = () => {
  const [image, setImage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({
    image: "",
  });
  const [showImage, setShowImage] = useState(null);
  const handleImageChange = (e) => {
    const image = e.target.files[0];
    if (image) {
      const fileType = image.type;
      const fileSize = image.size / 1024 / 1024; // Convert file size to MB
  
      if (!fileType.startsWith("image/")) {
        setError((prev) => {
          return { ...prev, image: "Please select a valid image file" };
        });
      } else if (fileSize > 1) {
        setError((prev) => {
          return { ...prev, image: "File size exceeds 1 MB" };
        });
      } else {
        var reader = new FileReader();
        reader.onload = function () {
          setImage(image);
          setShowImage(reader.result);
        };
        reader.readAsDataURL(image);
        setError((prev) => {
          return { ...prev, image: "" };
        });
      }
    }
  };
  const handleSubmit=()=>{
    if(image===null){

        toast.error("Please add  image ");
      
    }else if(error.image !==""){
      setError((pre)=>{
        return {...pre,youtube:"Youtube Link is reqiured"};
      })
    }
    else{
      setLoading(true);
      postImage()
    }
  
  
      
  }
  const handleClick = (e) => {
    e.target.value = null; // Reset input value to allow re-selecting the same file
  };
  const postImage=async()=>{
    try{
      const formData = new FormData();
      formData.append("image", image);
      const response=await client.post("/imagegallery/postimage",formData,{
        withCredentials:true
      });
      setLoading(false);
      if(response.status===201){
        toast.success("Image uploaded successfully");
        setImage(null);
        setShowImage(null);
        setError((prev)=>{
          return {...prev,youtube:""};
        })
      }
    }catch(err){
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Failed to add image gallery");
      }
      }
    
  }
  return (
<Fragment>
<main id="main" className="main">
      <div className="pagetitle">
        <h1>Dashboard</h1>
        <nav style={{
            marginTop:"10px"
        }}>
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Gallery/Images</li>
          </ol>
        </nav>
      </div>
      <section
          className="section dashboard"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "70px",
          }}
        >
          <div className="card" style={{ width: "100%", maxWidth: "400px" }}>
            <div
              className="card-body"
              style={{
                padding: "20px",
              }}
            >
              <h5
                className="card-title"
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                Add Image
              </h5>
              <div  className="products-image-button">
                <div >
              <FormLabel>
                     
                    <p style={{ fontSize: "12px", color: "black" }}>
                    Image (choose One Image)
                    </p>
                  </FormLabel>
                  </div>
                  <div style={{
                    marginLeft:"100px"
                  }}>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    style={{ marginTop: "10px" }}
                    disabled={image !== null}
                  >
                    Gallery Image
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleImageChange}
                      accept="image/*"
                      onClick={handleClick} // Reset value on click
                    />
                  </Button>
                
                  {error.image && (
                    <div
                      style={{
                        color: "red",
                        marginLeft: "20px",
                        fontSize: "13px",
                      }}
                    >
                      {error.image}
                    </div>
                  )}
                  {showImage !== null ? (
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        marginTop: "10px",
                        width: "200px",
                      }}
                    >
                      <img
                        src={showImage}
                        alt=""
                        width="100px"
                        height="100px"
                        style={{
                          marginTop: "0px",
                        }}
                      />
                      <Button
                        color="error"
                        variant="contained"
                        onClick={() => {
                          setShowImage(null);
                          setImage(null);
                        }}
                        style={{
                          marginTop: "0px",
                          textAlign: "center",
                        }}
                      >
                        Delete
                      </Button>
                    </div>) :<></>}
                    </div>
              </div>

                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  style={{ display: "block", margin: "20px auto" }}
                >
                  Submit
                </Button>
            </div>
          </div>
        </section>
 
    </main>
    {loading && <Loader />}
</Fragment>
)
}

export default Images