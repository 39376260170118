import React, { Fragment, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, FormHelperText, TextField } from "@mui/material";
import Grid from "@mui/material/Grid2";
import { FormLabel } from "@mui/material";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import client from "../../Common/Client/Client";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
import toast from "react-hot-toast";

const Course = () => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const navigate=useNavigate()
  const [courseName, setCourseName] = useState("");
  const [whyChooseUs, setWhyChooseUs] = useState("");
  const [aboutCourse, setAboutCourse] = useState("");
  const [examStructure, setExamStructure] = useState([
    { component: "", duration: "", description: "" },
  ]);
  const [packageMonth, setPackageMonth] = useState([
    { packageName: "", packagePrice:"",packageDuration: "", keyFeatures: "" },
  ]);
  const [academicYear, setAcademicYear] = useState([{ month: "", dates: [] }]);

  const [coursePreparation, setCoursePreparation] = useState("");
  const [courseCoaching, setCourseCoaching] = useState("");
  const [coachingExcellence, setCoachingExcellence] = useState("");
  const [purpose, setPurpose] = useState("");
  const [focus, setFocus] = useState("");
  const [testFormat, setTestFormat] = useState("");
  const [error, setError] = useState({
    courseName: "",
    whyChooseUs: "",
    aboutCourse: "",
    purpose: "",
    focus: "",
    testFormat: "",
    coursePreparation: "",
    courseCoaching: "",
    coachingExcellence: "",
    component: "",
    duration: "",
    description: "",
    month: "",
    dates: "",
    packageName: "",
    packageDuration: "",
    keyFeatures: "",
    packagePrice:""
  });

  const isAddMonth = () => {
    return academicYear.some(
      (value) => value.month === "" || value.dates.length === 0
    );
  };

  const isAddDisabled = () => {
    return examStructure.some(
      (structure) =>
        structure.component === "" ||
        structure.duration === "" ||
        structure.description === ""
    );
  };

  const isAddPackage = () => {
    return packageMonth.some(
      (value) =>
        value.keyFeatures === "" ||
        value.packageDuration === "" ||
        value.packageName === "" ||
        value.packagePrice === ""
    );
  };

  const isPackageError = () => {
    return (
      error.keyFeatures !== "" ||
      error.packageDuration !== "" ||
      error.packageName !== "" ||
      error.packagePrice!==""
    );
  };

  const isError = () => {
    return (
      error.duration !== "" ||
      error.component !== "" ||
      error.description !== ""
    );
  };

  const handlePackageMonthChange = (index, e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;
    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    if (name === "packageName") {
      setPackageMonth(
        packageMonth.map((item, i) =>
          i === index ? { ...item, packageName: e.target.value } : item
        )
      );
    }else if(name==="packagePrice"){
      setPackageMonth(
        packageMonth.map((item, i) =>
          i === index ? { ...item, packagePrice: e.target.value } : item
        )
      );
    } else {
      setPackageMonth(
        packageMonth.map((item, i) =>
          i === index ? { ...item, packageDuration: e.target.value } : item
        )
      );
    }
  };

  const handleAddPackageMonth = () => {
    setPackageMonth([
      ...packageMonth,
      {
        packageName: "",
        keyFeatures: "",
        packageDuration: "",
      },
    ]);
  };

  const handleAddStructure = () => {
    setExamStructure([
      ...examStructure,
      { component: "", duration: "", description: "" },
    ]);
  };

  const errorMessage = (fieldName, fieldValue) => {
    let message;
    if (fieldName) {
      if (fieldValue === "") {
        message = "";
      }
    }

    if (fieldName === "courseName") {
      if (fieldValue.length < 3) {
        message = `Course Name is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "whyChooseUs") {
      if (fieldValue.length < 100) {
        message = "Why Choose us  must above 100 character";
      } else {
        message = "";
      }
    }
    if (fieldName === "aboutCourse") {
      if (fieldValue.length < 100) {
        message = `About course must above 100 character`;
      } else {
        message = "";
      }
    }
    if (fieldName === "purpose") {
      if (fieldValue.length < 50) {
        message = `Purpose must above 50 character`;
      } else {
        message = "";
      }
    }
    if (fieldName === "focus") {
      if (fieldValue.length < 50) {
        message = `Focus must above 50 character`;
      } else {
        message = "";
      }
    }
    if (fieldName === "testFormat") {
      if (fieldValue.length < 50) {
        message = `Test Format must above 50 character`;
      } else {
        message = "";
      }
    }

    if (fieldName === "courseCoaching") {
      if(fieldValue===""){
        message = ""
      }
    else if (fieldValue.length < 100) {
        message = `Course Coaching must above 100 character`;
      } else {
        message = "";
      }
    }
    if (fieldName === "coursePreparation") {
      if(fieldValue===""){
        message = ""
      }
    else if (fieldValue.length < 100) {
        message = `Course Preparation must above 100 character`;
      } else {
        message = "";
      }
    }
    if (fieldName === "coachingExcellence") {
      if(fieldValue===""){
        message = ""
      }
    else  if (fieldValue.length < 100) {
        message = `Coaching Excellence must above 100 character`;
      } else {
        message = "";
      }
    }
    if (fieldName === "duration") {
      if (fieldValue.length < 3) {
        message = `Duration is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "component") {
      if (fieldValue.length < 3) {
        message = `Component is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "description") {
      if (fieldValue.length < 20) {
        message = `Description is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "month") {
      if (fieldValue.length < 3) {
        message = `Months is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "packageName") {
      if (fieldValue.length < 3) {
        message = `Package Name is Invalid`;
      } else {
        message = "";
      }
    }

    if (fieldName === "packageDuration") {
      if (fieldValue.length < 3) {
        message = `Package Duration is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "packagePrice") {
      if (fieldValue.length < 3) {
        message = `Package Price is Invalid`;
      } else {
        message = "";
      }
    }

    if (fieldName === "keyFeatures") {
      if (fieldValue.length < 20) {
        message = `Key feature  is Invalid`;
      } else {
        message = "";
      }
    }

    return { message: message };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;

    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    if (name === "courseName") {
      setCourseName(value);
    } else if (name === "whyChooseUs") {
      setWhyChooseUs(value);
    } else if (name === "aboutCourse") {
      setAboutCourse(value);
    } else if (name === "purpose") {
      setPurpose(value);
    } else if (name === "focus") {
      setFocus(value);
    } else if (name === "testFormat") {
      setTestFormat(value);
    } else if (name === "coachingExcellence") {
      setCoachingExcellence(value);
    } else if (name === "coursePreparation") {
      setCoursePreparation(value);
    } else {
      setCourseCoaching(value);
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setError((prevError) => ({
        ...prevError,
        [name]: `${name} is required`,
      }));
    }
  };

  const handleRemoveStructure = (index) => {
    if (examStructure.length > 1) {
      const newStructure = examStructure.filter((_, i) => i !== index);
      setExamStructure(newStructure);
      setError((pre) => {
        return { ...pre, component: "", duration: "", description: "" };
      });
    }
  };

  const handleRemovePackageMonth = (index) => {
    if (packageMonth.length > 1) {
      const newPackageMonth = packageMonth.filter((_, i) => i !== index);
      setPackageMonth(newPackageMonth);
      setError((pre) => {
        return {
          ...pre,
          packageDuration: "",
          packageName: "",
          keyFeatures: "",
        };
      });
    }
  };

  const handleStructureChange = (index, e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;
    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));

    if (name === "duration") {
      setExamStructure(
        examStructure.map((item, i) =>
          i === index ? { ...item, duration: e.target.value } : item
        )
      );
    } else {
      setExamStructure(
        examStructure.map((item, i) =>
          i === index ? { ...item, component: e.target.value } : item
        )
      );
    }
  };
  const handleDateChange = (date, index) => {
    // const formattedDates = dates.map((date) => date.format("DD/MM/YYYY"));
  console.log(date)
    const updatedAcademicYear = academicYear.map((item, i) =>
      i === index
        ? {
            ...item,
            dates: date.map((vlaue) => {
              return vlaue.format("MMMM DD YYYY");
            }),
          }
        : item
    );
    setAcademicYear(updatedAcademicYear);
  };
console.log(academicYear)
  const handleMonthChange = (index, e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;
    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    setAcademicYear(
      academicYear.map((item, i) =>
        i === index ? { ...item, month: e.target.value } : item
      )
    );
  };

  const handleDescriptionChange = (index, e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;
    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    const updatedExamStructure = examStructure.map((item, i) =>
      i === index ? { ...item, description: value } : item
    );
    setExamStructure(updatedExamStructure);
  };

  const handlePackageFeaturesChange = (index, e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;
    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    const updatePackage = packageMonth.map((item, i) =>
      i === index ? { ...item, keyFeatures: value } : item
    );
    setPackageMonth(updatePackage);
  };

  const handleAddAcademicYear = () => {
    setAcademicYear([...academicYear, { month: "", dates: [] }]);
  };

  const handleRemoveAcademicYear = (index) => {
    if (academicYear.length > 1) {
      const newAcademicYear = academicYear.filter((_, i) => i !== index);
      setAcademicYear(newAcademicYear);
      setError((pre) => {
        return { ...pre, dates: "", month: "" };
      });
    }
  };

  console.log(aboutCourse);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      courseName === "" ||
      whyChooseUs === "" ||
      aboutCourse === "" ||
      purpose === "" ||
      focus === "" ||
      testFormat === ""
    ) {
      toast.error("Please fill all the fields");
      return false;
    } else if (
      examStructure.some(
        (item) =>
          item.component === "" ||
          item.duration === "" ||
          item.description === ""
      )
    ) {
      toast.error("Please fill all the fields in the exam structure");
    } else if ( packageMonth.some((item)=>item.keyFeatures==="" || item.packageDuration===""||item.packageName==="" || item.packagePrice==="")){
      toast.error("Please fill all the fields in the package");
    }else if (
      error.courseName !== "" ||
      error.whyChooseUs !== "" ||
      error.aboutCourse !== "" ||
      error.purpose !== "" ||
      error.focus !== "" ||
      error.testFormat !== "" ||
      error.coursePreparation !== "" ||
      error.courseCoaching !== "" ||
      error.coachingExcellence !== "" ||
      error.component !== "" ||
      error.duration !== "" ||
      error.description !== "" ||
      error.month !== "" ||
      error.dates !== "" ||
      error.packageName !== "" ||
      error.packageDuration !== "" ||
      error.keyFeatures !== "" ||
      error.packagePrice !== ""
    ) {
      toast.error("Check error,Please fill all field");
    } else {
      try {
        const aboutCourseArray = aboutCourse
          .split("\n")
          .map((item) => item.trim());
        const coursePreparationArray = coursePreparation
          .split("\n")
          .map((item) => item.trim());
        const courseCoachingArray = courseCoaching
          .split("\n")
          .map((item) => item.trim());
        const coachingExcellenceArray = coachingExcellence
          .split("\n")
          .map((item) => item.trim());
        const processedExamStructure = examStructure.map((item) => ({
          ...item,
          description: item.description.split("\n").map((desc) => desc.trim()),
        }));

        const packageArray = packageMonth.map((item) => {
          return {
            ...item,
            keyFeatures: item.keyFeatures
              .split("\n")
              .map((feature) => feature.trim()),
          };
        });
        console.log(packageArray);
        const formData = {
          courseName,
          whyChooseUs,
          aboutCourse: aboutCourseArray,
          coursePreparation: coursePreparationArray,
          courseCoaching: courseCoachingArray,
          coachingExcellence: coachingExcellenceArray,
          examStructure: processedExamStructure,
          package: packageArray,
          academicYear,
          purpose,
          focus,
          testFormat,
        };
        console.log(formData)

        const response = await client.post("/course/addcourse", formData,{
          withCredentials:true
        });
        if (response.status === 200) {
          toast.success("Course Added Successfully");
          navigate("/")
          setPurpose("");
          setWhyChooseUs("")
          setFocus("");
          setTestFormat("");
          setCourseName("");
          setAboutCourse("");
          setCoursePreparation("");
          setCourseCoaching("");
          setCoachingExcellence("");
          setExamStructure([{ component: "", duration: "", description: "" }]);
          setPackageMonth([
            {
              packageDuration: "",
              packageName: "",
              keyFeatures: "",
            },
          ]);
          setAcademicYear([
            {
              month: "",
              dates: "",
            },
          ]);
         
        }
      } catch (err) {
        
        if (err.response && err.response.status === 401) {
          toast.error("Login again");
        } else {
          toast.error("Failed to add course details");
        }
      }
    }
  };

  console.log(whyChooseUs);
  return (
    <Fragment>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Dashboard</h1>
          <nav
            style={{
              marginTop: "10px",
            }}
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active">Course/Add Course</li>
            </ol>
          </nav>
        </div>
        <section
          className="section dashboard"
          style={{
            display: "flex",
            width: "100% ",
            marginTop: "70px",
          }}
        >
          <div className="card" style={{ width: "100%" }}>
            <div
              className="card-body"
              style={{
                padding: "20px",
              }}
            >
              <h5
                className="card-title"
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                Add Course
              </h5>

              <form noValidate autoComplete="off" style={{ width: "100%" }}>
                <Grid container spacing={2}>
                  <Grid item size={{ xs: 12, sm: 6 }}>
                    <TextField
                      label="Course Name"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      value={courseName}
                      helperText={error.courseName}
                      error={!!error.courseName}
                      required
                      onChange={handleChange}
                      name="courseName"
                      slotProps={{
                        htmlInput: {
                          maxLength: 50,
                        },
                      }}
                      onKeyDown={(e) => {
                        const allowedKeys = [
                          "Backspace",
                          "ArrowLeft",
                          "ArrowRight",
                          "Delete",
                          "Tab",
                          " ",
                        ];
                        const allowedCharPattern = /^[A-Za-z0-9()._-]$/;
                  
                        // Prevent spaces as the first character
                        if (courseName.length === 0 && e.key === " ") {
                          e.preventDefault();
                          return;
                        }
                  
                        // Check if the pressed key is not allowed
                        if (
                          !allowedKeys.includes(e.key) &&
                          !allowedCharPattern.test(e.key)
                        ) {
                          e.preventDefault(); // Prevent the default action of the disallowed key
                        }
                      }}
                      onBlur={handleBlur}
                    />
                  </Grid>
                  <Grid item size={{ xs: 12, sm: 6 }}>
                    <TextField
                      label="Why Choose Us?"
                      variant="outlined"
                      fullWidth
                      multiline
                      margin="normal"
                      name="whyChooseUs"
                      value={whyChooseUs}
                      helperText={error.whyChooseUs}
                      error={!!error.whyChooseUs}
                      required
                      onChange={handleChange}
                      slotProps={{
                        htmlInput: {
                          maxLength: 300,
                        },
                      }}
                      onBlur={handleBlur}
                      onKeyDown={(e) => {
                       
                  
                        // Prevent spaces as the first character
                        if (whyChooseUs.length === 0 && e.key === " ") {
                          e.preventDefault();
                          return;
                        }
                  
                        // Check if the pressed key is not allowed
                      
                      }}
                    />
                  </Grid>
                  <Grid item size={{ xs: 12 }}>
                    <TextField
                      label="About Course"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      required
                      multiline
                      value={aboutCourse}

                      helperText={error.aboutCourse}
                      error={!!error.aboutCourse}
                      name="aboutCourse"
                      onChange={handleChange}
                      rows={4}
                      onBlur={handleBlur}
                   
                    
                      onKeyDown={(e) => {
                       
                  
                        // Prevent spaces as the first character
                        if (aboutCourse.length === 0 && e.key === " ") {
                          e.preventDefault();
                          return;
                        }
                  
                        // Check if the pressed key is not allowed
                      
                      }}
                    />
                  </Grid>
                  <Grid item size={{ xs: 12 }}>
                    <TextField
                      label="Purpose"
                      variant="outlined"
                      fullWidth
                      rows={2}
                      margin="normal"
                      required
                      helperText={error.purpose}
                      error={!!error.purpose}
                      multiline
                      name="purpose"
                      value={purpose}

                      onChange={handleChange}
                      onBlur={handleBlur}
                      slotProps={{
                        htmlInput: {
                          maxLength: 500,
                        },
                      }}
                    
                      onKeyDown={(e) => {
                     
                  
                        // Prevent spaces as the first character
                        if (purpose.length === 0 && e.key === " ") {
                          e.preventDefault();
                          return;
                        }
                  
                        // Check if the pressed key is not allowed
                      
                      }}
                    />
                  </Grid>
                  <Grid item size={{ xs: 12 }}>
                    <TextField
                      label="Focus"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      helperText={error.focus}
                      error={!!error.focus}
                      value={focus}

                      required
                      rows={2}
                      multiline
                      name="focus"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      slotProps={{
                        htmlInput: {
                          maxLength: 500,
                        },
                      }}
                    
                      onKeyDown={(e) => {
                       
                  
                        // Prevent spaces as the first character
                        if (focus.length === 0 && e.key === " ") {
                          e.preventDefault();
                          return;
                        }
                  
                        // Check if the pressed key is not allowed
                      
                      }}
                    />
                  </Grid>
                  <Grid item size={{ xs: 12 }}>
                    <TextField
                      label="Test Format"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      helperText={error.testFormat}
                      error={!!error.testFormat}
                      required
                      rows={2}
                      value={testFormat}

                      multiline
                      name="testFormat"
                      onChange={handleChange}
                      onBlur={handleBlur}
                      slotProps={{
                        htmlInput: {
                          maxLength: 500,
                        },
                      }}
                    
                      onKeyDown={(e) => {
                       
                  
                        // Prevent spaces as the first character
                        if (testFormat.length === 0 && e.key === " ") {
                          e.preventDefault();
                          return;
                        }
                  
                        // Check if the pressed key is not allowed
                      
                      }}
                    />
                  </Grid>
                  <Grid item size={{ xs: 12 }}>
                    <TextField
                      label="Why Dream live Stands Out for Your course Preparation?"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      multiline
                      helperText={error.coursePreparation}
                      error={!!error.coursePreparation}
                      name="coursePreparation"
                      value={coursePreparation}

                      onChange={handleChange}
                      rows={4}
                      // slotProps={{
                      //   htmlInput: {
                      //     maxLength: 1000,
                      //   },
                      // }}
                    
                      onKeyDown={(e) => {
                       
                  
                        // Prevent spaces as the first character
                        if (coursePreparation.length === 0 && e.key === " ") {
                          e.preventDefault();
                          return;
                        }
                  
                        // Check if the pressed key is not allowed
                      
                      }}
                    />
                  </Grid>
                  <Grid item size={{ xs: 12 }}>
                    <TextField
                      label="Our Approach towards the Course Coaching"
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      helperText={error.courseCoaching}
                      error={!!error.courseCoaching}
                      name="courseCoaching"
                      onChange={handleChange}
                      multiline
                      rows={4}
                      value={courseCoaching}
                     
                    
                      onKeyDown={(e) => {
                       
                  
                        // Prevent spaces as the first character
                        if (courseCoaching.length === 0 && e.key === " ") {
                          e.preventDefault();
                          return;
                        }
                  
                        // Check if the pressed key is not allowed
                      
                      }}

                    />
                  </Grid>
                  <Grid item size={{ xs: 12 }}>
                    <TextField
                      label="Dream Live sets out the Standard in course Coaching Excellence"
                      variant="outlined"
                      fullWidth
                      helperText={error.coachingExcellence}
                      error={!!error.coachingExcellence}
                      name="coachingExcellence"
                      onChange={handleChange}
                      margin="normal"
                      multiline
                      rows={4}
                      value={coachingExcellence}
                      // slotProps={{
                      //   htmlInput: {
                      //     maxLength: 1000,
                      //   },
                      // }}
                    
                      onKeyDown={(e) => {
                       
                  
                        // Prevent spaces as the first character
                        if (coachingExcellence.length === 0 && e.key === " ") {
                          e.preventDefault();
                          return;
                        }
                  
                        // Check if the pressed key is not allowed
                      
                      }}

                    />
                  </Grid>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Grid container textAlign="left" spacing={2}>
                      <Grid
                        item
                        size={{
                          xs: 12,
                          sm: 3,
                        }}
                      >
                        <FormLabel>
                          <span>Academic Year</span>
                        </FormLabel>
                      </Grid>
                      <Grid
                        item
                        xs={12}
                        sm={3}
                        container
                        justifyContent="flex-end"
                      >
                        <Button
                          variant="contained"
                          color="success"
                          style={{ marginRight: "10px" }}
                          onClick={handleAddAcademicYear}
                          disabled={isAddMonth()}
                        >
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                    {academicYear.map((year, index) => (
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        key={index}
                      >
                        <Grid item size={{ xs: 12, sm: 4 }}>
                          <FormControl
                            fullWidth
                            margin="normal"
                            variant="outlined"
                          >
                            <InputLabel id={`month-label-${index}`}>
                              Month
                            </InputLabel>
                            <Select
                              labelId={`month-label-${index}`}
                              label="Month"
                              value={year.month}
                              name="month"
                              onChange={(e) => handleMonthChange(index, e)}
                            >
                              {months.map((month, idx) => (
                                <MenuItem key={idx} value={month}>
                                  {month}
                                </MenuItem>
                              ))}
                            </Select>
                          </FormControl>
                        </Grid>
                        <Grid item size={{ xs: 12, sm: 4 }}>
                          <DatePicker
                            placeholder="Select the Dates"
                            style={{
                              height: "50px",
                            }}
                            format="MMMM DD YYYY"
                            sort
                            multiple
                            onChange={(dates) => handleDateChange(dates, index)}
                            // value={year.dates}
                            plugins={[<DatePanel />]}
                          />
                        </Grid>
                        <Grid
                          item
                          size={{ xs: 12, sm: 4 }}
                          justifyContent="flex-end"
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleRemoveAcademicYear(index)}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </Grid>
                    ))}

                    <FormHelperText error>{error.month}</FormHelperText>
                    <FormHelperText error>{error.dates}</FormHelperText>
                  </div>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Grid container textAlign="left" spacing={2}>
                      <Grid
                        item
                        size={{
                          xs: 12,
                          sm: 3,
                        }}
                      >
                        <FormLabel>
                          <span>Structure of Course Exam</span>
                        </FormLabel>
                      </Grid>
                      <Grid item xs={12} sm={3} container>
                        <Button
                          variant="contained"
                          color="success"
                          style={{ marginRight: "10px" }}
                          onClick={handleAddStructure}
                          disabled={isAddDisabled() || isError()}
                        >
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                    {examStructure.map((structure, index) => (
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        key={index}
                      >
                        <Grid item size={{ xs: 12, sm: 3 }}>
                          <TextField
                            label="Component"
                            variant="outlined"
                            fullWidth
                            required
                            margin="normal"
                            value={structure.component}
                            onBlur={handleBlur}
                            name="component"
                            onChange={(e) => {
                              handleStructureChange(index, e);
                            }}
                            slotProps={{
                              htmlInput: {
                                maxLength: 50,
                              },
                            }}
                          
                            onKeyDown={(e) => {
                             
                        
                              // Prevent spaces as the first character
                              if (structure.component.length === 0 && e.key === " ") {
                                e.preventDefault();
                                return;
                              }
                        
                              // Check if the pressed key is not allowed
                            
                            }}
                          />
                        </Grid>
                        <Grid item size={{ xs: 12, sm: 3 }}>
                          <TextField
                            label="Duration"
                            variant="outlined"
                            fullWidth
                            required
                            margin="normal"
                            name="duration"
                            value={structure.duration}
                            onBlur={handleBlur}
                            onChange={(e) => {
                              handleStructureChange(index, e);
                            }}
                            slotProps={{
                              htmlInput: {
                                maxLength: 50,
                              },
                            }}
                          
                            onKeyDown={(e) => {
                              
                        
                              // Prevent spaces as the first character
                              if (structure.duration.length === 0 && e.key === " ") {
                                e.preventDefault();
                                return;
                              }
                        
                              // Check if the pressed key is not allowed
                            
                            }}
                          />
                        </Grid>
                        <Grid item size={{ xs: 12, sm: 4 }}>
                          <TextField
                            label="Description"
                            variant="outlined"
                            fullWidth
                            required
                            rows={3}
                            multiline
                            name="description"
                            margin="normal"
                            onBlur={handleBlur}
                            value={structure.description}
                            onChange={(e) => handleDescriptionChange(index, e)}
                            slotProps={{
                              htmlInput: {
                                maxLength: 500,
                              },
                            }}
                          
                            onKeyDown={(e) => {
                            
                        
                              // Prevent spaces as the first character
                              if (structure.description.length === 0 && e.key === " ") {
                                e.preventDefault();
                                return;
                              }
                        
                              // Check if the pressed key is not allowed
                            
                            }}
                          />
                        </Grid>

                        <Grid
                          item
                          size={{ xs: 12, sm: 2 }}
                          justifyContent="flex-end"
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleRemoveStructure(index)}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </Grid>
                    ))}
                    <FormHelperText error>{error.component}</FormHelperText>
                    <FormHelperText error>{error.duration}</FormHelperText>
                    <FormHelperText error>{error.description}</FormHelperText>
                  </div>
                  <div
                    style={{
                      width: "100%",
                    }}
                  >
                    <Grid container textAlign="left" spacing={2}>
                      <Grid
                        item
                        size={{
                          xs: 12,
                          sm: 3,
                        }}
                      >
                        <FormLabel>
                          <span>Package Month Details</span>
                        </FormLabel>
                      </Grid>
                      <Grid item xs={12} sm={3} container>
                        <Button
                          variant="contained"
                          color="success"
                          style={{ marginRight: "10px" }}
                          onClick={handleAddPackageMonth}
                          disabled={isAddPackage() || isPackageError()}
                        >
                          Add
                        </Button>
                      </Grid>
                    </Grid>
                    {packageMonth.map((packageMonth, index) => (
                      <Grid
                        container
                        alignItems="center"
                        spacing={2}
                        key={index}
                      >
                        <Grid item size={{ xs: 12, sm: 2 }}>
                          <TextField
                            label="Package Name"
                            variant="outlined"
                            fullWidth
                            required
                            margin="normal"
                            value={packageMonth.packageName}
                            onBlur={handleBlur}
                            name="packageName"
                            onChange={(e) => handlePackageMonthChange(index, e)}
                            slotProps={{
                              htmlInput: {
                                maxLength: 20,
                              },
                            }}
                          
                            onKeyDown={(e) => {
                             
                              // Prevent spaces as the first character
                              if (packageMonth.packageName.length === 0 && e.key === " ") {
                                e.preventDefault();
                                return;
                              }
                        
                              // Check if the pressed key is not allowed
                            
                            }}
                          />
                        </Grid>
                        <Grid item size={{ xs: 12, sm: 2 }}>
                          <TextField
                            label="Package Price"
                            variant="outlined"
                            fullWidth
                            required
                            margin="normal"
                            value={packageMonth.packagePrice}
                            onBlur={handleBlur}
                            name="packagePrice"
                            onChange={(e) => handlePackageMonthChange(index, e)}
                            slotProps={{
                              htmlInput: {
                                maxLength: 20,
                              },
                            }}
                          
                            onKeyDown={ (e) => {
                              const allowedKeys = [
                                "Backspace",
                                "ArrowLeft",
                                "ArrowRight",
                                "Delete",
                                "Tab",
                              ];
                              const allowedCharPattern = /^[0-9+]$/;
        
                              // Check if the pressed key is not allowed
                              if (
                                !allowedKeys.includes(e.key) &&
                                !allowedCharPattern.test(e.key)
                              ) {
                                e.preventDefault(); // Prevent the default action of the disallowed key
                              }
                              
                              const currentValue = packageMonth.packagePrice || '';
                              if (currentValue.length === 0 && e.key === ' ') {
                                e.preventDefault();
                              }
                            }}
                          />
                        </Grid>
                        <Grid item size={{ xs: 12, sm: 2 }}>
                          <TextField
                            label="Duration (in months)"
                            variant="outlined"
                            fullWidth
                            required
                            margin="normal"
                            name="packageDuration"
                            value={packageMonth.packageDuration}
                            onBlur={handleBlur}
                            onChange={(e) => handlePackageMonthChange(index, e)}
                            slotProps={{
                              htmlInput: {
                                maxLength: 20,
                              },
                            }}
                          
                            onKeyDown={(e) => {
                             
                        
                              // Prevent spaces as the first character
                              if (packageMonth.packageDuration.length === 0 && e.key === " ") {
                                e.preventDefault();
                                return;
                              }
                        
                              // Check if the pressed key is not allowed
                            
                            }}
                          />
                        </Grid>
                        <Grid item size={{ xs: 12, sm: 4 }}>
                          <TextField
                            label="Key Features"
                            variant="outlined"
                            fullWidth
                            required
                            rows={3}
                            multiline
                            name="keyFeatures"
                            margin="normal"
                            onBlur={handleBlur}
                            value={packageMonth.keyFeatures}
                            onChange={(e) =>
                              handlePackageFeaturesChange(index, e)
                            }
                            slotProps={{
                              htmlInput: {
                                maxLength: 500,
                              },
                            }}
                          
                            onKeyDown={
                              (e) => {
                                if (packageMonth.keyFeatures.length === 0 && e.key === " ") {
                                  e.preventDefault();
                                }
                              }
                            }
                          />
                        </Grid>

                        <Grid
                          item
                          size={{ xs: 12, sm: 2 }}
                          justifyContent="flex-end"
                        >
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleRemovePackageMonth(index)}
                          >
                            Delete
                          </Button>
                        </Grid>
                      </Grid>
                    ))}
                    <FormHelperText error>{error.packageName}</FormHelperText>
                    <FormHelperText error>{error.packagePrice}</FormHelperText>
                    <FormHelperText error>
                      {error.packageDuration}
                    </FormHelperText>
                    <FormHelperText error>{error.keyFeatures}</FormHelperText>
                  </div>
                </Grid>
                <Button
                  variant="contained"
                  style={{ display: "block", margin: "20px auto" }}
                  onClick={handleSubmit}
                >
                  Submit
                </Button>
              </form>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default Course;
