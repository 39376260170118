import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import client from "../../../Common/Client/Client";
import {  Table } from "react-bootstrap";
import { message, Popconfirm } from "antd";
import { Button, styled } from "@mui/material";
import toast from "react-hot-toast";

const ManageEnqiure = () => {
  const [enqiure, setEnqiure] = useState([]);
  useEffect(() => {
    fetchEnqiure();
  },[]);

  const fetchEnqiure = async () => {
    try {
      const response = await client.get("/user/get",{
        withCredentials:true
      });
      if (response.status === 200) {
        setEnqiure(response.data);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to fetch enqiure details");
      }
    }
  };


  const cancel = (e) => {
    message.error("Cancle delete");
  };



  const handleDelete =async (id) => {
    try{
        const response=await client.post('/user/delete',{id:id},{
          withCredentials:true
        })
        if(response.status===200){
            toast.success("Delete Success")
            setEnqiure(enqiure.filter((enqiure) => enqiure._id !== id));
        }
    }catch(err){
        console.log(err)
        if (err.response && err.response.status === 401) {
          toast.error("Login again");
        } else {
          toast.error("Failed to delete enqiure details");
        }
    }
  };
  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Dashboard</h1>
        <nav
          style={{
            marginTop: "10px",
          }}
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Manage/Enqiure</li>
          </ol>
        </nav>
      </div>

      <div className="container mt-4">
        <h2>Manage Enqiure</h2>
        <div className="table-responsive">
          <Table striped bordered hover responsive>
            <thead className="thead-dark">
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Message</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {enqiure.length > 0 ? (
                enqiure.map((item) => (
                  <tr key={item.id}>
                    <td>{item.firstName}</td>
                    <td>{item.lastName}</td>
                    <td>{item.email}</td>
                    <td>{item.phoneNumber}</td>
                    <td>{item.message}</td>
                    <td>
                    
                      <Popconfirm
                      title="Delete the Image"
                      description="Are you sure to delete this Image?"
                      onConfirm={() => {
                        handleDelete(item._id);
                      }}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button variant="contained"    className="table-button" color="error">
                        Delete
                      </Button>
                    </Popconfirm>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    No Enqiure found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </main>
  );
};

export default ManageEnqiure;
