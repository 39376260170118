import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import client from "../../../Common/Client/Client";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import toast from "react-hot-toast";

const Videos = () => {
  const [videoUrl, setVideoUrl] = useState("");
  const [error, setError] = useState({
    youtube: "",
  });
  const errorMessage = (fieldName, fieldValue) => {
    let message;
    if (fieldName) {
      if (fieldValue === "") {
        message = "";
      }
    }

    if (fieldName === "youtube") {
      const youtubeRegex =
        /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/|v\/|.+\?v=)|youtu\.be\/).+$/;
      if (!youtubeRegex.test(fieldValue)) {
        message = `Please enter a valid YouTube URL`;
      } else {
        message = "";
      }
    }

    return { message: message };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;

    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    setVideoUrl(value);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setError((prevError) => ({
        ...prevError,
        [name]: `${name} is required`,
      }));
    }
  };

  const handleSubmit =  (e) => {
    e.preventDefault();
    if(videoUrl===""){
      toast.error("Please add YouTube link ");
    }else if(error.youtube !==""){
      setError((pre)=>{
        return {...pre,youtube:"Youtube Link is reqiured"};
      })
    }
    else{
      postLink()
    }
  
  };

  const postLink=async()=>{
    try {
      const response = await client.post("/gallery/youtubeurl", { videoUrl },{
        withCredentials:true
      });
      if (response.status === 201) {
        toast.success("YouTube link added successfully ");
        setVideoUrl("");
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to add youtube Url details");
      }
    }
  }

  return (
    <Fragment>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Dashboard</h1>
          <nav
            style={{
              marginTop: "10px",
            }}
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active">Gallery/Videos</li>
            </ol>
          </nav>
        </div>
        <section
          className="section dashboard"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "70px",
          }}
        >
          <div className="card" style={{ width: "100%", maxWidth: "400px" }}>
            <div
              className="card-body"
              style={{
                padding: "20px",
              }}
            >
              <h5
                className="card-title"
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                Add Youtube Links
              </h5>
              <div>
                <TextField
                  id="standard-basic"
                  type="link"
                  className="form"
                  label="Video URL"
                  variant="standard"
                  value={videoUrl}
                  name="youtube"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={error.youtube ? error.youtube : ""}
                  error={!!error.youtube}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  fullWidth
                  margin="normal"
                />

                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  style={{ display: "block", margin: "20px auto" }}
                >
                  Submit
                </Button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default Videos;
