import React, { useState, useEffect } from "react";
import client from "../../../Common/Client/Client";
import { Link } from "react-router-dom";
import { message, Popconfirm } from "antd";
import { Button, styled } from "@mui/material";
import { Modal, Form } from "react-bootstrap";
import { FormLabel } from "@mui/material";
import toast from "react-hot-toast";
import './MangeVideos.css'
import TextField from "@mui/material/TextField";

const ManageVideos = () => {
  const [urls, setUrls] = useState([]);
  const [id, setId] = useState("");
  const [url, setUrl] = useState("");
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    youtube: "",
  });
  useEffect(() => {
    getImage();
  }, []);

  const getImage = async () => {
    try {
      const response = await client.get("/gallery/getyoutubeurl");
      if (response.status === 200) {
        setUrls(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const cancel = (e) => {
    message.error("Cancle delete");
  };

  const handleDelete = async (id) => {
    try {
      const response = await client.post(`/gallery/deleteyoutubeurls`, {
        id: id,
      },{
        withCredentials:true
      });
      if (response.status === 200) {
        setUrls(urls.filter((item) => item._id !== id));
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to delete youtube Url details");
      }
    }
  };

  //update

  const handleUpdate = (id, value) => {
    setOpen(true);
    setId(id);
    setUrl(value.videoUrl);
  };

  const errorMessage = (fieldName, fieldValue) => {
    let message;
    if (fieldName) {
      if (fieldValue === "") {
        message = "";
      }
    }

    if (fieldName === "youtube") {
      const youtubeRegex =
        /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/|v\/|.+\?v=)|youtu\.be\/).+$/;
      if (!youtubeRegex.test(fieldValue)) {
        message = `Please enter a valid YouTube URL`;
      } else {
        message = "";
      }
    }

    return { message: message };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;

    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    setUrl(value);
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setError((prevError) => ({
        ...prevError,
        [name]: `${name} is required`,
      }));
    }
  };

  const updateNewUrl = () => {
    if (url === "") {
      toast.error("Please add YouTube link ");
    } else if (error.youtube !== "") {
      setError((pre) => {
        return { ...pre, youtube: "Youtube Link is reqiured" };
      });
    } else {
      change();
    }
  };

  const change = async () => {
    try {
      const response = await client.post("/gallery/updateUrl", {
        id,
        url,
      },{
        withCredentials:true
      });
      if (response.status === 200) {
        const updateUrl = urls.map((value) => {
            console.log(response)
          if (value._id === id) {
            return {
              ...value,
              videoUrl: response.data.videoUrl,
            };
          }
          return value;
        });
        setUrls(updateUrl);
        toast.success("Url updated successfully");
        setUrl("");
        setError((prevError) => {
          return { ...prevError, youtube: "" };
        });
        setOpen(false);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to update youtube Url details");
      }
    }
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Dashboard</h1>
        <nav
          style={{
            marginTop: "10px",
          }}
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Manage/Youtube Urls</li>
          </ol>
        </nav>
      </div>

      <div className="container">
        <h3>Manage YouTube Urls</h3>
        <div class="table-responsive">
        <table className="table table-striped table-bordered custom-table">
          <thead>
            <tr>
              <th>YouTube Urls</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {urls.length > 0 ? (
              urls.map((url) => (
                <tr key={url.id}>
                  <td>
                    <span style={{
                      textAlign:"center"
                    }}>{url.videoUrl}</span>
                  </td>

                  <td>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdate(url._id, url)}
                         className="table-button"
                         style={{
                          marginRight:"5px"
                         }}
                    >
                      Update
                    </Button>
                    <Popconfirm
                      title="Delete the Image"
                      description="Are you sure to delete this Image?"
                      onConfirm={() => {
                        handleDelete(url._id);
                      }}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                         className="table-button"
                    >
                      <Button variant="contained"    className="table-button" color="error">
                        Delete
                      </Button>
                    </Popconfirm>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center">
                  No images available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        </div>
        <div>
          <Modal
            show={open}
            onHide={() => {
              setOpen(false);
              setUrl("");
              setError((pre) => {
                return { ...pre, videoUrl: "" };
              });
            }}
          >
            <Modal.Header closeButton>
              <Modal.Title>YouTube URL</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <div className="update-form">
                  <Form.Group>
                    <div className="up-main-image">
                      <div>
                        <FormLabel>
                          <span> YouTube URL</span>
                        </FormLabel>
                      </div>
                      <div className="mt-3 p-3">
                        <TextField
                          id="standard-basic"
                          type="link"
                          className="form-control"
                          label="Video URL"
                          variant="standard"
                          value={url}
                          name="youtube"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          helperText={error.youtube ? error.youtube : ""}
                          error={!!error.youtube}
                          onKeyDown={(e) => {
                            if (e.key === " ") {
                              e.preventDefault();
                            }
                          }}
                          fullWidth
                          margin="normal"
                        />
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="contained"
                onClick={updateNewUrl}
                color="success"
              >
                Save changes
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    </main>
  );
};

export default ManageVideos;
