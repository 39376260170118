import React, { useState,useEffect } from "react";
import { Link,useNavigate } from "react-router-dom";
import toast from "react-hot-toast";
import { FormLabel } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import client from "../../Common/Client/Client";
import Loader from "../../Common/Loader/Loader";
import { TextField, styled,Button, Box } from "@mui/material";
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const About = () => {
const navigate=useNavigate()
  const [image, setImage] = useState(null);
  const [about, setAbout] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showAddImage, setShowAddImage] = useState(0);
  const [showImage, setShowImage] = useState(null);
  const [error, setError] = useState({
    image: "",
  });

  const [id,setId]=useState("")

  useEffect(()=>{
    getAbouts()
  },[])
  const getAbouts=async()=>{
    try{
      const response=await client.get('/about/getabout')
      if(response.status===200){
        const data=response.data[0]
        setImage(data.image)
        const aboutEntries = data.about.map((value) => ({ value: value, error: "" }));
        setAbout(aboutEntries)
            setId(data._id)
       
      }
    }catch(err){
      console.log(err)

    }
  }

  console.log(about,id,image)
  const handleImageChange = (e) => {
    const image = e.target.files[0];
    if (image) {
      const fileType = image.type;
      const fileSize = image.size / 1024 / 1024; // Convert file size to MB

      if (!fileType.startsWith("image/")) {
        setError((prev) => {
          return { ...prev, image: "Please select a valid image file" };
        });
      } else if (fileSize > 1) {
        setError((prev) => {
          return { ...prev, image: "File size exceeds 1 MB" };
        });
      } else {
        var reader = new FileReader();
        reader.onload = function () {
          setImage(image);
          setShowImage(reader.result);
        };
        reader.readAsDataURL(image);
        setError((prev) => {
          return { ...prev, image: "" };
        });
      }
    }
  };


  const handleAboutChange = (index, e) => {
    const { value } = e.target;
    const newAbout = [...about];
    newAbout[index].value = value;

    if (value.length < 200) {
      newAbout[index].error = "About content must be at least 200 characters.";
    } else {
      newAbout[index].error = "";
    }

    setAbout(newAbout);
  };

  // Handle About field blur (to show errors when losing focus)
  const handleAboutBlur = (index, e) => {
    const { value } = e.target;
    const newAbout = [...about];
    if (value === "") {
      newAbout[index].error = "About content is required.";
    }
    setAbout(newAbout);
  };
  const addAboutField = () => {
    if (about.length >= 3) {
      toast.error("Maximum of 3 About fields can be added.");
      return;
    }

    const lastField = about[about.length - 1];
    if (lastField.value === "") {
      toast.error("Complete the current field.");
    } else if (lastField.error) {
      toast.error("Fix the error in about field");
    } else {
      setAbout([...about, { value: "", error: "" }]);
    }
  };

  // Delete About content field
  const deleteAboutField = (index) => {
    if (about.length <= 1) {
      toast.error("At least one About field must remain.");
      return;
    }

    const newAbout = [...about];
    newAbout.splice(index, 1);
    setAbout(newAbout);
  };

  const handleSubmit = () => {
    if (about.some((value, index) => value.value === "")) {
      toast.error("Fill the all input fileds");
    } else if (image === null) {
      toast.error("Please  upload image for about us");
    } else if (error.image !== "") {
      toast.error(error.image);
    } else if (about.some((value, index) => value.error !== "")) {
      toast.error("Fix the error in about field");
    } else {
      sendData();
    }
  };

  const sendData = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("image", image);
      formData.append("id", id);
      about.forEach((field, index) => {
        formData.append("about", field.value);
      });
      const response = await client.post("/about/addabout", formData,{
        withCredentials:true
      });
      if (response.status === 200) {
        toast.success("About us Update successfully!");
       
        setLoading(false);
        navigate("/")
      }
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to add about us details");
      }
    }
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Dashboard</h1>
        <nav
          style={{
            marginTop: "10px",
          }}
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">/manage/About Us</li>
          </ol>
        </nav>
      </div>
      <section
        className="section dashboard"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "70px",
        }}
      >
        <div className="card" style={{ width: "100%", maxWidth: "400px" }}>
          <div
            className="card-body"
            style={{
              padding: "20px",
            }}
          >
            <h5
              className="card-title"
              style={{ textAlign: "center", marginBottom: "20px" }}
            >
              update About us
            </h5>
            <form>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h5 style={{ textAlign: "center" }}>About Content</h5>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    onClick={addAboutField}
                  >
                    Add
                  </Button>
                </Box>
              </div>

              {about.map((field, index) => (
                <Box
                  key={index}
                  sx={{
                    marginBottom: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    label={`About Content ${index + 1}`}
                    fullWidth
                    variant="outlined"
                    slotProps={{
                      htmlInput: {
                        maxLength: 1000,
                      },
                    }}
                    rows={2}
                    multiline
                    value={field.value}
                    onChange={(e) => handleAboutChange(index, e)}
                    onBlur={(e) => handleAboutBlur(index, e)}
                    error={!!field.error}
                    helperText={field.error}
                    onKeyDown={(e) => {
                      const allowedKeys = [
                        "Backspace",
                        "ArrowLeft",
                        "ArrowRight",
                        "Delete",
                        "Tab",
                        " ",
                      ];
                      const allowedCharPattern = /^[A-Za-z.,-_ ]$/; // Allow letters, spaces, and some special characters

                      // Prevent spaces as the first character
                      if (field.value.length === 0 && e.key === " ") {
                        e.preventDefault();
                        return;
                      }

                      // Check if the pressed key is not allowed
                      if (
                        !allowedKeys.includes(e.key) &&
                        !allowedCharPattern.test(e.key)
                      ) {
                        e.preventDefault(); // Prevent the default action of the disallowed key
                      }
                    }}
                  />
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => deleteAboutField(index)}
                    disabled={about.length <= 1}
                    sx={{ marginLeft: "10px" }}
                  >
                    Delete
                  </Button>
                </Box>
              ))}
              <Box
                sx={{
                  marginBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
               <div className="" style={{ width: "100%" }}>
               <FormLabel
                  sx={{
                    marginBottom: "20px",
                  }}
                >
                  <span>About Image {showAddImage===1 &&<span>(choose one Image)</span>}</span>
                </FormLabel>
                      <div style={{ marginTop: "20px" }}>
                        {showAddImage === 1 ? (
                          <>
                            <Button
                              component="label"
                              role={undefined}
                              variant="contained"
                              tabIndex={-1}
                              startIcon={<CloudUploadIcon />}
                              style={{ marginTop: "10px" }}
                              disabled={image !== null}
                            >
                              change Image
                              <VisuallyHiddenInput
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                onClick={(e)=>{
                                  e.target.value = null;
                                }}
                              />
                            </Button>
                            <div>
                              {showImage === null ? (
                                <></>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                    marginTop: "10px",
                                  }}
                                >
                                  <img
                                    src={showImage}
                                    alt=""
                                    width="100px"
                                    height="100px"
                                  />
                                  <Button
                                    color="error"
                                    variant="contained"
                                    onClick={() => {
                                      setShowImage(null);
                                      setImage(null);
                                      setShowAddImage(1);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className="image-delete-button">
                            <img
                              src={image}
                              alt=" "
                              width="100px"
                              height="100px"
                            />
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => {
                                setShowAddImage(1);
                                setImage(null);
                                setShowImage(null);

                              }}
                              style={{
                                marginLeft: "10px",
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        )}
                        {error.image && (
                          <div
                            style={{
                              color: "red",
                              marginLeft: "20px",
                              fontSize: "13px",
                            }}
                          >
                            {error.image}
                          </div>
                        )}
                      </div>
                    </div>
              </Box>
              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  update 
                </Button>
              </Box>
            </form>
          </div>
        </div>
      </section>
      {loading && <Loader />}
    </main>
  );
};

export default About;
