import React, { useEffect, useState } from "react";
import client from "../../../Common/Client/Client";
import toast from "react-hot-toast";
import { Link } from "react-router-dom";
import { Table, Modal, Form } from "react-bootstrap";
import { Button, TextField, FormLabel } from "@mui/material";
import { message, Popconfirm } from "antd";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { styled } from "@mui/material/styles";
import Loader from "../../../Common/Loader/Loader";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const ManageBlogs = () => {
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(false);
  const [blogs, setBlogs] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [deleteImage, setDeleteImage] = useState(0);
  const [selectedBlog, setSelectedBlog] = useState({
    name: "",
    location: "",
    date: "",
    description: [],
    image: null,
  });

  const [error, setError] = useState({
    name: "",
    location: "",
    date: "",
    description: "",
    image: "",
  });

  useEffect(() => {
    getBlogs();
  }, []);

  const getBlogs = async () => {
    try {
      const response = await client.get("/blog/get");
      if (response.status === 200) {
        setBlogs(response.data);
      } else {
        toast.error("Error occuring in fetching data");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const cancel = (e) => {
    message.error("Cancle delete");
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setError((prevError) => ({
        ...prevError,
        [name]: `${name} is required`,
      }));
    }
  };

  const errorMessage = (fieldName, fieldValue) => {
    let message;
    if (fieldName) {
      if (fieldValue === "") {
        message = "";
      }
    }

    if (fieldName === "name") {
      if (fieldValue.length < 3) {
        message = `Name is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "location") {
      if (fieldValue.length < 3) {
        message = `Review is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "description") {
      if (fieldValue.length < 250) {
        message = `Description need above 250 character `;
      } else {
        message = "";
      }
    }
    if (fieldName === "date") {
      if (!fieldValue) {
        message = "Date is required";
      } else {
        message = "";
      }
    }
    return { message: message };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;

    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    setSelectedBlog((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // Convert size to MB

      if (!fileType.startsWith("image/")) {
        setSelectedBlog((pre) => ({
          ...pre,
          image: null,
        }));
        setError((pre) => {
          return { ...pre, image: "Please select an valid image file." };
        });
      } else if (fileSize > 1) {
        setSelectedBlog((pre) => ({
          ...pre,
          image: null,
        }));
        setError((pre) => {
          return { ...pre, image: "File size exceeds 1 MB." };
        });
      } else {
        setSelectedBlog((pre) => ({
          ...pre,
          image: file,
        }));
        setError((prev) => {
          return { ...prev, image: "" };
        });
      }
    }
  };
  const handleClick = (e) => {
    e.target.value = null;
  };

  const handleDelete = async (id) => {
    try {
      const response = await client.post("/blog/delete", { id },{
        withCredentials:true
      });
      if (response.status === 204) {
        toast.success("Blog delete Successfully");
        setBlogs(blogs.filter((blog) => blog._id !== id));
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to delete blog details");
      }
    }
  };

  const handleDescription = (e) => {
    const { name, value } = e.target;

    const err = errorMessage(name, value).message;
    setError((prevError) => ({
      ...prevError,
      description: err,
    }));
    setSelectedBlog((prevData) => ({
      ...prevData,
      description: value.split("\n").map((item)=> item.replace(/^•\s*/, "").trim())
    }));
  };

  const openUpdateModal = (blog) => {
    setSelectedBlog(blog);
    setId(blog._id);
    setShowModal(true);
  };

  console.log(error);

  const handleSubmit = () => {
    if (
      selectedBlog.image === null ||
      selectedBlog.date === "" ||
      selectedBlog.description === "" ||
      selectedBlog.location === "" ||
      selectedBlog.name === ""
    ) {
      toast.error("Please fill all the fields");
    } else if (
      error.date !== "" ||
      error.description !== "" ||
      error.image !== "" ||
      error.name !== "" ||
      error.location !== ""
    ) {
      toast.error("Please check error in  all the fields");
    } else {
      setLoading(true);
      giveBlog();
    }
  };
  console.log(selectedBlog.description)
  const giveBlog = async () => {
    try {
    
       
      const formData = new FormData();
      formData.append("id", id);
      formData.append("image", selectedBlog.image);
      formData.append("date", selectedBlog.date);
    selectedBlog.description.forEach((item) => {
        if (item.trim() !== "") { 
          formData.append("description", item);
        }
      })
      formData.append("location", selectedBlog.location);
      formData.append("name", selectedBlog.name);

      console.log(formData.get("date"));

      const response = await client.post("/blog/update", formData,{
        withCredentials:true
      });
      if (response.status === 200) {
        const updatedBlog = response.data;
        setBlogs((prevBlogs) =>
          prevBlogs.map((blog) =>
            blog._id === updatedBlog._id ? updatedBlog : blog
          )
        );

        setError({
          name: "",
          date: "",
          location: "",
          description: "",
          image: "",
        });
        setSelectedBlog({
          name: "",
          location: "",
          date: "",
          description: "",
          image: null,
        });
        setLoading(false);
        setShowModal(false);

        toast.success("Blog Updated Successfully");
      } else {
        setLoading(false);
        toast.error("Failed to add blog");
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        setLoading(false);
        toast.error("Login again");
      } else {
        setLoading(false);
        toast.error("Failed to update blog details");
      }
      
    }
  };

  const handleClose = () => {
    setShowModal(false);
    setLoading(false);
    setError({
      name: "",
      date: "",
      location: "",
      description: "",
      image: "",
    });
  };
  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Dashboard</h1>
        <nav
          style={{
            marginTop: "10px",
          }}
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Manage/Blogs</li>
          </ol>
        </nav>
      </div>
      <section className="section dashboard">
      <div className="container mt-4">
      <h2>Manage Blog</h2>
        <div className="table-responsive">
          <Table striped bordered hover className="text-center">
            <thead>
              <tr>
                <th>S.no</th>
                <th>Image</th>
                <th>Name</th>
                <th>Location</th>
                <th>Date</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {blogs.length > 0 ? (
                blogs.map((blog, index) => (
                  <tr key={blog._id}>
                    <td
                      style={{
                        textAlign: "center",
                      }}
                    >
                      {index + 1}
                    </td>
                    <td>
                      <img
                        src={blog.image}
                        alt={blog.name}
                        width="50"
                        height="50"
                        style={{ objectFit: "cover" }}
                      />
                    </td>
                    <td>{blog.name}</td>
                    <td>{blog.location}</td>
                    <td>{blog.date}</td>
                    <td>
                      <Button
                        color="success"
                        variant="contained"
                        style={{
                          margin: "5px",
                        }}
                        onClick={() => openUpdateModal(blog)}
                      >
                        Update
                      </Button>
                      <Popconfirm
                        title="Delete the Image"
                        description="Are you sure to delete this Image?"
                        onConfirm={() => {
                          handleDelete(blog._id);
                        }}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                      >
                        <Button
                          variant="contained"
                          className="table-button"
                          color="error"
                          style={{
                            margin: "5px",
                          }}
                        >
                          Delete
                        </Button>
                      </Popconfirm>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    No Blog found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
        </div>
      </section>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Update Blog</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Blog Name</Form.Label>
              <TextField
                label="Blog Name"
                name="name"
                fullWidth
                error={!!error.name}
                helperText={error.name}
                value={selectedBlog.name}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ marginBottom: "15px" }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Location</Form.Label>
              <TextField
                label="Location"
                name="location"
                fullWidth
                onBlur={handleBlur}
                error={!!error.location}
                helperText={error.location}
                value={selectedBlog.location}
                onChange={handleChange}
                style={{ marginBottom: "15px" }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Date</Form.Label>
              <TextField
                name="date"
                placeholder="select Date"
                type="date"
                onBlur={handleBlur}
                error={!!error.date}
                helperText={error.date}
                fullWidth
                value={selectedBlog.date}
                onChange={handleChange}
                style={{ marginBottom: "15px" }}
              />
            </Form.Group>
            <Form.Group>
              <TextField
                label="Description"
                name="description"
                multiline
                rows={4}
                fullWidth
                onBlur={handleBlur}
                error={!!error.description}
                helperText={error.description}
                value={
                  Array.isArray(selectedBlog.description)
                    ? selectedBlog.description.map((value) => `• ${value}`).join("\n") 
                    : selectedBlog.description 
                }
                onChange={handleDescription}
                style={{ marginBottom: "15px" }}
              />
            </Form.Group>
            <Form.Group>
              <Form.Label>Image URL</Form.Label>
              {deleteImage === 0 ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-around",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={selectedBlog.image}
                    width="50px"
                    height="50px"
                    alt=""
                  />
                  <Button
                    variant="contained"
                    onClick={() => {
                      setSelectedBlog((pre) => ({
                        ...pre,
                        image: "",
                      }));
                      setDeleteImage(1);
                    }}
                    className="table-button"
                    color="error"
                    style={{
                      height: "36px",
                    }}
                  >
                    Delete
                  </Button>
                </div>
              ) : (
                <div>
                  <div>
                    <FormLabel>
                      <p style={{ fontSize: "17px", color: "black" }}>Image</p>
                    </FormLabel>
                  </div>
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                    style={{ marginTop: "10px", marginLeft: "20px" }}
                  >
                    Gallery Image
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleImageChange}
                      accept="image/*"
                      onClick={handleClick} // Reset value on click
                    />
                  </Button>
                  {selectedBlog.image && <p>{selectedBlog.image.name}</p>}
                  {error.image && (
                    <div
                      style={{
                        color: "red",
                        marginLeft: "20px",
                        fontSize: "13px",
                      }}
                    >
                      {error.image}
                    </div>
                  )}
                </div>
              )}
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Modal.Footer>
        {loading && <Loader />}
      </Modal>
    </main>
  );
};

export default ManageBlogs;
