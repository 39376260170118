import React, { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import client from "../../../Common/Client/Client";
import { Table, Modal } from "react-bootstrap";
import { Button, TextField, FormHelperText } from "@mui/material";
import { Select, MenuItem } from "@mui/material";
import { message, Popconfirm } from "antd";
import DatePicker from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel";
import "./ManageCourse.css";
import toast from "react-hot-toast";

const ManageCourse = () => {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const Navigate=useNavigate()
  const [id, setId] = useState("");
  const [course, setCourse] = useState([]);
  const [show, setShow] = useState(false);
  const [courseName, setCourseName] = useState("");
  const [whyChooseUs, setWhyChooseUs] = useState("");
  const [aboutCourse, setAboutCourse] = useState(null);
  const [examStructure, setExamStructure] = useState([
    { component: "", duration: "", description: null },
  ]);
  const [packageMonth, setPackageMonth] = useState([
    { packageName: "", packagePrice: "", packageDuration: "", keyFeatures: null },
  ]);
  const [academicYear, setAcademicYear] = useState([{ month: "", dates: [] }]);
  const [coursePreparation, setCoursePreparation] = useState(null);
  const [courseCoaching, setCourseCoaching] = useState(null);
  const [coachingExcellence, setCoachingExcellence] = useState(null);
  const [purpose, setPurpose] = useState("");
  const [focus, setFocus] = useState("");
  const [testFormat, setTestFormat] = useState("");
  const [error, setError] = useState({
    courseName: "",
    whyChooseUs: "",
    aboutCourse: "",
    purpose: "",
    focus: "",
    testFormat: "",
    coursePreparation: "",
    courseCoaching: "",
    coachingExcellence: "",
    component: "",
    duration: "",
    description: "",
    month: "",
    dates: "",
    packageName: "",
    packageDuration: "",
    keyFeatures: "",
    packagePrice: "",
  });
  useEffect(() => {
    fetchCourse();
  }, []);

  const fetchCourse = async () => {
    try {
      const response = await client.get("/course/getcourse");
      if (response.status === 200) {
        setCourse(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const handleShow = (course) => {
    console.log(course);
    setId(course._id);
    setCourseName(course.courseName);
    setWhyChooseUs(course.whyChooseUs);
    setAboutCourse(course.aboutCourse);
    setCourseCoaching(course.courseCoaching);
    setCoursePreparation(course.coursePreparation);
    setCoachingExcellence(course.coachingExcellence);
    setPurpose(course.purpose);
    setFocus(course.focus);
    setTestFormat(course.testFormat);
    setExamStructure(course.examStructure);
    setAcademicYear(course.academicYear);
    setPackageMonth(course.package);
    setShow(true);
  };

  //
  const handleClose = () => {
    setShow(false);
    setError((pre) => ({
      ...pre,
      courseName: "",
      whyChooseUs: "",
      aboutCourse: "",
      purpose: "",
      focus: "",
      testFormat: "",
      coursePreparation: "",
      courseCoaching: "",
      coachingExcellence: "",
      component: "",
      duration: "",
      description: "",
      month: "",
      dates: "",
      packageName: "",
      packageDuration: "",
      keyFeatures: "",
      packagePrice: "",
    }));
  };
  //Button disabaled
  const isAddMonth = () => {
    return academicYear.some(
      (value) => value.month === "" || value.dates.length === 0
    );
  };
  const isAddDisabled = () => {
    return examStructure.some(
      (structure) =>
        structure.component === "" ||
        structure.duration === "" ||
        structure.description === ""
    );
  };
  const isError = () => {
    return (
      error.duration !== "" ||
      error.component !== "" ||
      error.description !== ""
    );
  };

  const isAddPackage = () => {
    return packageMonth.some(
      (value) =>
        value.keyFeatures === "" ||
        value.packageDuration === "" ||
        value.packageName === "" ||
        value.packagePrice === ""
    );
  };

  const isPackageError = () => {
    return (
      error.keyFeatures !== "" ||
      error.packageDuration !== "" ||
      error.packageName !== "" ||
      error.packagePrice !== ""
    );
  };
  //Adding Input feilds
  const handleAddItem = () => {
    setExamStructure([
      ...examStructure,
      { component: "", duration: "", description: "" },
    ]);
  };
  const handleAddYear = () => {
    setAcademicYear([...academicYear, { month: "", dates: [] }]);
  };

  const handleAddPackage = () => {
    setPackageMonth([
      ...packageMonth,
      {
        packageName: "",
        packagePrice: "",
        packageDuration: "",
        keyFeatures: "",
      },
    ]);
  };

  //Deleting Inpu fields
  const handleDeleteItem = (index) => {
    const newExamStructure = examStructure.filter((_, i) => i !== index);
    setExamStructure(newExamStructure);
  };

  const handleDeleteYear = (index) => {
    setAcademicYear(academicYear.filter((_, i) => i !== index));
  };

  const handleDeletePackage = (index) => {
    setPackageMonth(packageMonth.filter((_, i) => i !== index));
  };

  //handle Change

  const errorMessage = (fieldName, fieldValue) => {
    let message;
    if (fieldName) {
      if (fieldValue === "") {
        message = "";
      }
    }

    if (fieldName === "courseName") {
      if (fieldValue.length < 3) {
        message = `Course Name is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "whyChooseUs") {
      if (fieldValue.length < 100) {
        message = "Why Choose us  must above 100 character";
      } else {
        message = "";
      }
    }
    if (fieldName === "aboutCourse") {
      if (fieldValue.length < 100) {
        message = `About course must above 100 character`;
      } else {
        message = "";
      }
    }
    if (fieldName === "purpose") {
      if (fieldValue.length < 50) {
        message = `Purpose must above 50 character`;
      } else {
        message = "";
      }
    }
    if (fieldName === "focus") {
      if (fieldValue.length < 50) {
        message = `Focus must above 50 character`;
      } else {
        message = "";
      }
    }
    if (fieldName === "testFormat") {
      if (fieldValue.length < 50) {
        message = `Test Format must above 50 character`;
      } else {
        message = "";
      }
    }

    if (fieldName === "courseCoaching") {
      if (fieldValue === "") {
        message = "";
      } else if (fieldValue.length < 100) {
        message = `Course Coaching must above 100 character`;
      } else {
        message = "";
      }
    }
    if (fieldName === "coursePreparation") {
      if (fieldValue === "") {
        message = "";
      } else if (fieldValue.length < 100) {
        message = `Course Preparation must above 100 character`;
      } else {
        message = "";
      }
    }
    if (fieldName === "coachingExcellence") {
      if (fieldValue === "") {
        message = "";
      } else if (fieldValue.length < 100) {
        message = `Coaching Excellence must above 100 character`;
      } else {
        message = "";
      }
    }
    if (fieldName === "duration") {
      if (fieldValue.length < 3) {
        message = `Duration is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "component") {
      if (fieldValue.length < 3) {
        message = `Component is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "description") {
      if (fieldValue.length < 20) {
        message = `Description is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "month") {
      if (fieldValue.length < 3) {
        message = `Months is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "packageName") {
      if (fieldValue.length < 3) {
        message = `Package Name is Invalid`;
      } else {
        message = "";
      }
    }

    if (fieldName === "packageDuration") {
      if (fieldValue.length < 3) {
        message = `Package Duration is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "packagePrice") {
      if (fieldValue.length < 3) {
        message = `Package Price is Invalid`;
      } else {
        message = "";
      }
    }

    if (fieldName === "keyFeatures") {
      if (fieldValue.length < 20) {
        message = `Key feature  is Invalid`;
      } else {
        message = "";
      }
    }

    return { message: message };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;

    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    if (name === "courseName") {
      setCourseName(value);
    } else if (name === "whyChooseUs") {
      setWhyChooseUs(value);
    } else if (name === "aboutCourse") {
      const updatedAboutCourse = value
      .split("\n")
      .map((item) => item.replace(/^•\s*/, "").trim()) // Remove bullets and trim
   
    // Set the state with the filtered array
    setAboutCourse(updatedAboutCourse);
    } else if (name === "purpose") {
      setPurpose(value);
    } else if (name === "focus") {
      setFocus(value);
    } else if (name === "testFormat") {
      setTestFormat(value);
    } else if (name === "coachingExcellence") {
      setCoachingExcellence(
        value.split("\n").map((item) => item.replace(/^•\s*/, "").trim())
      );
    } else if (name === "coursePreparation") {
      setCoursePreparation(
        value.split("\n").map((item) => item.replace(/^•\s*/, "").trim())
      );
    } else {
      setCourseCoaching(
        value.split("\n").map((item) => item.replace(/^•\s*/, "").trim())
      );
    }
  };

  console.log(aboutCourse)

  const handleStructureChange = (index, e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;
    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));

    if (name === "duration") {
      setExamStructure(
        examStructure.map((item, i) =>
          i === index ? { ...item, duration: e.target.value } : item
        )
      );
    } else {
      setExamStructure(
        examStructure.map((item, i) =>
          i === index ? { ...item, component: e.target.value } : item
        )
      );
    }
  };

  const handleDescriptionChange = (index, e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;
    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    const updatedExamStructure = examStructure.map((item, i) =>
      i === index
        ? {
            ...item,
            description: value
              .split("\n")
              .map((item) => item.replace(/^•\s*/, "")),
          }
        : item
    );

    
    setExamStructure(updatedExamStructure);
  };

  const handlePackageFeaturesChange = (index, e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;
    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    const updatePackage = packageMonth.map((item, i) =>
      i === index
        ? {
            ...item,
            keyFeatures: value
              .split("\n")
              .map((item) => item.replace(/^•\s*/, "").trim()),
          }
        : item
    );
    setPackageMonth(updatePackage);
  };
  const handlePackageMonthChange = (index, e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;
    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    if (name === "packageName") {
      setPackageMonth(
        packageMonth.map((item, i) =>
          i === index ? { ...item, packageName: e.target.value } : item
        )
      );
    } else if (name === "packagePrice") {
      setPackageMonth(
        packageMonth.map((item, i) =>
          i === index ? { ...item, packagePrice: e.target.value } : item
        )
      );
    } else {
      setPackageMonth(
        packageMonth.map((item, i) =>
          i === index ? { ...item, packageDuration: e.target.value } : item
        )
      );
    }
  };

  const handleMonthChange = (index, e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;
    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    setAcademicYear(
      academicYear.map((item, i) =>
        i === index ? { ...item, month: e.target.value } : item
      )
    );
  };

  const handleDateChange = (date, index) => {
    // const formattedDates = dates.map((date) => date.format("DD/MM/YYYY"));
    console.log(date);
    const updatedAcademicYear = academicYear.map((item, i) =>
      i === index
        ? {
            ...item,
            dates: date.map((vlaue) => {
              return vlaue.format("MMMM DD YYYY");
            }),
          }
        : item
    );
    setAcademicYear(updatedAcademicYear);
  };

  console.log(examStructure);

  //Blur
  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setError((prevError) => ({
        ...prevError,
        [name]: `${name} is required`,
      }));
    }
  };

  const handleSubmit = () => {
    if (
      courseName === "" ||
      whyChooseUs === "" ||
      aboutCourse === "" ||
      purpose === "" ||
      focus === "" ||
      testFormat === ""
    ) {
      toast.error("Please fill all the fields");
      return false;
    } else if (
      examStructure.some(
        (item) =>
          item.component === "" ||
          item.duration === "" ||
          item.description === ""
      )
    ) {
      toast.error("Please fill all the fields in the exam structure");
    } else if (
      packageMonth.some(
        (item) =>
          item.keyFeatures === "" ||
          item.packageDuration === "" ||
          item.packageName === "" ||
          item.packagePrice === ""
      )
    ) {
      toast.error("Please fill all the fields in the package");
    } else if (
      error.courseName !== "" ||
      error.whyChooseUs !== "" ||
      error.aboutCourse !== "" ||
      error.purpose !== "" ||
      error.focus !== "" ||
      error.testFormat !== "" ||
      error.coursePreparation !== "" ||
      error.courseCoaching !== "" ||
      error.coachingExcellence !== "" ||
      error.component !== "" ||
      error.duration !== "" ||
      error.description !== "" ||
      error.month !== "" ||
      error.dates !== "" ||
      error.packageName !== "" ||
      error.packageDuration !== "" ||
      error.keyFeatures !== "" ||
      error.packagePrice !== ""
    ) {
      toast.error("Check error,Please fill all field");
    } else if (examStructure.length === 0) {
      toast.error("Please add at least one Exam Structure");
    } else if (packageMonth.length === 0) {
      toast.error("Please add at least one package ");
    } else {
      update();
    }
  };

  const update = async () => {
    console.log(aboutCourse)
    try {

      const formData = {
        courseName: courseName,
        whyChooseUs: whyChooseUs,
        aboutCourse: aboutCourse.filter((value)=>value.length > 0),
        purpose: purpose,
        focus: focus,
        testFormat: testFormat,
        package: packageMonth.map((item)=>({
          packageName: item.packageName,
          packageDuration: item.packageDuration,
          packagePrice:item.packagePrice,
          keyFeatures:item.keyFeatures.filter((line)=>line.length>0)

        })),
        examStructure: examStructure.map((item)=>({
          component:item.component,duration:item.duration,description:item.description.filter((line)=>line.length>0)
        })),
        academicYear: academicYear,
        coursePreparation: coursePreparation.filter((value)=>value.length > 0),
        courseCoaching: courseCoaching.filter((value)=>value.length > 0),
        coachingExcellence: coachingExcellence.filter((value)=>value.length > 0),
        id: id,
      };
      const response = await client.post("/course/update", formData,{
        withCredentials:true
      });
      if (response.status === 200) {
        const update=course.map((value)=>{
          if(value._id===id){
            return {...value,...formData}
          }
          return value
        })
        setCourse(update)
        toast.success("Course Updated Successfully");
        setError((pre)=>{
          return {...pre,   courseName: "",
            whyChooseUs: "",
            aboutCourse: "",
            purpose: "",
            focus: "",
            testFormat: "",
            coursePreparation: "",
            courseCoaching: "",
            coachingExcellence: "",
            component: "",
            duration: "",
            description: "",
            month: "",
            dates: "",
            packageName: "",
            packageDuration: "",
            keyFeatures: "",
            packagePrice: "",}
        })
        Navigate("/")
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to update course details");
      }
    }
  };
  const cancel = (e) => {
    message.error("Cancle delete");
  };

  const handleDelete = async (id) => {
   
    const formData={
      id:id
    }
    console.log(formData)
    try {
      const response = await client.post("/course/delete", formData,{
        withCredentials:true
      });
      if (response.status === 200) {
        toast.success("Delete Success");
        setCourse(course.filter((course) => course._id !== id));
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to delete course details");
      }
    }
  };
  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Dashboard</h1>
        <nav
          style={{
            marginTop: "10px",
          }}
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Manage/Enqiure</li>
          </ol>
        </nav>
      </div>
      <section className="section dashboard">
        <div className="container mt-4">
          <h2>Manage Course</h2>
          <div className="table-responsive">
            <Table striped bordered hover className="text-center">
              <thead>
                <tr>
                  <th>S.no</th>
                  <th>Name</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {course.length > 0 ? (
                  course.map((course, index) => (
                    <tr key={course._id}>
                      <td
                        style={{
                          textAlign: "center",
                        }}
                      >
                        {index + 1}
                      </td>
                      <td>{course.courseName}</td>

                      <td>
                        <Button
                          color="success"
                          variant="contained"
                          style={{
                            margin: "5px",
                          }}
                          onClick={() => handleShow(course)}
                        >
                          Update
                        </Button>
                        <Popconfirm
                          title="Delete the Image"
                          description="Are you sure to delete this Image?"
                          onConfirm={() => {
                            handleDelete(course._id);
                          }}
                          onCancel={cancel}
                          okText="Yes"
                          cancelText="No"
                        >
                          <Button
                            variant="contained"
                            className="table-button"
                            color="error"
                            style={{
                              margin: "5px",
                            }}
                          >
                            Delete
                          </Button>
                        </Popconfirm>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" className="text-center">
                      No Course found.
                    </td>
                  </tr>
                )}
              </tbody>
            </Table>
          </div>
        </div>
        <Modal show={show} onHide={handleClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Course</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <form>
              <TextField
                label="Course Name"
                variant="outlined"
                fullWidth
                margin="normal"
                helperText={error.courseName}
                error={!!error.courseName}
                required
                onChange={handleChange}
                name="courseName"
                slotProps={{
                  htmlInput: {
                    maxLength: 20,
                  },
                }}
                onKeyDown={(e) => {
                  const allowedKeys = [
                    "Backspace",
                    "ArrowLeft",
                    "ArrowRight",
                    "Delete",
                    "Tab",
                    " ",
                  ];
                  const allowedCharPattern = /^[A-Za-z._-]$/;

                  // Prevent spaces as the first character
                  if (courseName.length === 0 && e.key === " ") {
                    e.preventDefault();
                    return;
                  }

                  // Check if the pressed key is not allowed
                  if (
                    !allowedKeys.includes(e.key) &&
                    !allowedCharPattern.test(e.key)
                  ) {
                    e.preventDefault(); // Prevent the default action of the disallowed key
                  }
                }}
                onBlur={handleBlur}
                value={courseName}
                //  onChange={handleChange}
              />

              <TextField
                label="Why Choose Us"
                variant="outlined"
                fullWidth
                margin="normal"
                name="whyChooseUs"
                value={whyChooseUs}
                helperText={error.whyChooseUs}
                error={!!error.whyChooseUs}
                required
                onChange={handleChange}
                onBlur={handleBlur}
                multiline
                rows={4}
                slotProps={{
                  htmlInput: {
                    maxLength: 300,
                  },
                }}
                onKeyDown={(e) => {
                  // Prevent spaces as the first character
                  if (whyChooseUs.length === 0 && e.key === " ") {
                    e.preventDefault();
                    return;
                  }

                  // Check if the pressed key is not allowed
                }}
              />

              <TextField
                label="About course"
                variant="outlined"
                fullWidth
                margin="normal"
                name="aboutCourse"
                value={
                  Array.isArray(aboutCourse)
                    ? aboutCourse.map((value) => `• ${value}`).join("\n")
                    : aboutCourse
                }
                multiline
                rows={4}
                helperText={error.aboutCourse}
                error={!!error.aboutCourse}
                onChange={handleChange}
                onBlur={handleBlur}
              
                onKeyDown={(e) => {
                  const cursorPosition = e.target.selectionStart;
              
                  // Prevent spaces as the first character
                  if (aboutCourse.length === 0 && e.key === " ") {
                    e.preventDefault();
                    return;
                  }
              
                  // Handle backspace key to remove bullet point and empty lines
                  if (e.key === 'Backspace') {
                    const value = e.target.value;
                    const textBeforeCursor = value.slice(0, cursorPosition);
                    const textAfterCursor = value.slice(cursorPosition);
              
                    // Check if backspace is attempting to delete a bullet point (dot) without text
                    if (textBeforeCursor.endsWith("• ") || textBeforeCursor.endsWith("•")) {
                      e.preventDefault(); // Prevent the default backspace behavior
              
                      // Update the aboutCourse state by removing the empty bullet point
                      const updatedAboutCourse = value
                        .split("\n")
                        .map((item) => item.replace(/^•\s*/, "").trim()) // Remove bullets and trim spaces
                        .filter((item) => item.length > 0); // Remove empty lines
              
                      setAboutCourse(updatedAboutCourse);
                    }
                  }
                }}
              />

              <TextField
                label="Purpose"
                variant="outlined"
                fullWidth
                margin="normal"
                name="purpose"
                value={purpose}
                //  onChange={handleChange}
                multiline
                rows={4}
                required
                helperText={error.purpose}
                error={!!error.purpose}
                onChange={handleChange}
                onBlur={handleBlur}
                slotProps={{
                  htmlInput: {
                    maxLength: 200,
                  },
                }}
                onKeyDown={(e) => {
                  // Prevent spaces as the first character
                  if (purpose.length === 0 && e.key === " ") {
                    e.preventDefault();
                    return;
                  }

                  // Check if the pressed key is not allowed
                }}
              />

              <TextField
                label="Focus"
                variant="outlined"
                fullWidth
                margin="normal"
                name="focus"
                value={focus}
                //  onChange={handleChange}
                multiline
                rows={4}
                onChange={handleChange}
                onBlur={handleBlur}
                helperText={error.focus}
                error={!!error.focus}
                slotProps={{
                  htmlInput: {
                    maxLength: 200,
                  },
                }}
                onKeyDown={(e) => {
                  // Prevent spaces as the first character
                  if (focus.length === 0 && e.key === " ") {
                    e.preventDefault();
                    return;
                  }

                  // Check if the pressed key is not allowed
                }}
              />

              <TextField
                label="Test Format"
                variant="outlined"
                fullWidth
                margin="normal"
                name="testFormat"
                value={testFormat}
                // onChange={handleChange}
                multiline
                helperText={error.testFormat}
                error={!!error.testFormat}
                onChange={handleChange}
                onBlur={handleBlur}
                rows={4}
                slotProps={{
                  htmlInput: {
                    maxLength: 200,
                  },
                }}
                onKeyDown={(e) => {
                  // Prevent spaces as the first character
                  if (testFormat.length === 0 && e.key === " ") {
                    e.preventDefault();
                    return;
                  }

                  // Check if the pressed key is not allowed
                }}
              />

              <TextField
                label="Course Preparation"
                variant="outlined"
                fullWidth
                margin="normal"
                name="coursePreparation"
                value={
                  Array.isArray(coursePreparation)
                    ? coursePreparation.map((value) => `• ${value}`).join("\n")
                    : coursePreparation
                }
                multiline
                rows={4}
                helperText={error.coursePreparation}
                error={!!error.coursePreparation}
                onChange={handleChange}
                slotProps={{
                  htmlInput: {
                    maxLength: 1000,
                  },
                }}
                onKeyDown={(e) => {
                  const cursorPosition = e.target.selectionStart;

                  // Prevent spaces as the first character
                  if (coursePreparation.length === 0 && e.key === " ") {
                    e.preventDefault();
                    return;
                  }
 // Handle backspace key to remove bullet point and empty lines
 if (e.key === 'Backspace') {
  const value = e.target.value;
  const textBeforeCursor = value.slice(0, cursorPosition);

  // Check if backspace is attempting to delete a bullet point (dot) without text
  if (textBeforeCursor.endsWith("• ") || textBeforeCursor.endsWith("•")) {
    e.preventDefault(); // Prevent the default backspace behavior

    // Update the aboutCourse state by removing the empty bullet point
    const update = value
      .split("\n")
      .map((item) => item.replace(/^•\s*/, "").trim()) // Remove bullets and trim spaces
      .filter((item) => item.length > 0); // Remove empty lines

    setCoursePreparation(update);
  }
}
                  // Check if the pressed key is not allowed
                }}
              />

              <TextField
                label="Course Coaching"
                variant="outlined"
                fullWidth
                margin="normal"
                name="courseCoaching"
                value={
                  Array.isArray(courseCoaching)
                    ? courseCoaching.map((value) => `• ${value}`).join("\n")
                    : courseCoaching
                }
                multiline
                rows={4}
                onChange={handleChange}
                helperText={error.courseCoaching}
                error={!!error.courseCoaching}
                slotProps={{
                  htmlInput: {
                    maxLength: 1000,
                  },
                }}
                onKeyDown={(e) => {
                  const cursorPosition = e.target.selectionStart;
                  // Prevent spaces as the first character
                  if (courseCoaching.length === 0 && e.key === " ") {
                    e.preventDefault();
                    return;
                  }
// Handle backspace key to remove bullet point and empty lines
if (e.key === 'Backspace') {
  const value = e.target.value;
  const textBeforeCursor = value.slice(0, cursorPosition);

  // Check if backspace is attempting to delete a bullet point (dot) without text
  if (textBeforeCursor.endsWith("• ") || textBeforeCursor.endsWith("•")) {
    e.preventDefault(); // Prevent the default backspace behavior

    // Update the aboutCourse state by removing the empty bullet point
    const update = value
      .split("\n")
      .map((item) => item.replace(/^•\s*/, "").trim()) // Remove bullets and trim spaces
      .filter((item) => item.length > 0); // Remove empty lines

    setCourseCoaching(update);
  }
}
                  // Check if the pressed key is not allowed
                }}
              />

              <TextField
                label="Coaching Excellence "
                variant="outlined"
                fullWidth
                margin="normal"
                name="coachingExcellence"
                value={
                  Array.isArray(coachingExcellence)
                    ? coachingExcellence.map((value) => `• ${value}`).join("\n")
                    : coachingExcellence
                }
                multiline
                rows={4}
                helperText={error.coachingExcellence}
                error={!!error.coachingExcellence}
                onChange={handleChange}
                slotProps={{
                  htmlInput: {
                    maxLength: 1000,
                  },
                }}
                onKeyDown={(e) => {
                  const cursorPosition = e.target.selectionStart;
                  // Prevent spaces as the first character
                  if (coachingExcellence.length === 0 && e.key === " ") {
                    e.preventDefault();
                    return;
                  }
// Handle backspace key to remove bullet point and empty lines
if (e.key === 'Backspace') {
  const value = e.target.value;
  const textBeforeCursor = value.slice(0, cursorPosition);

  // Check if backspace is attempting to delete a bullet point (dot) without text
  if (textBeforeCursor.endsWith("• ") || textBeforeCursor.endsWith("•")) {
    e.preventDefault(); // Prevent the default backspace behavior

    // Update the aboutCourse state by removing the empty bullet point
    const update = value
      .split("\n")
      .map((item) => item.replace(/^•\s*/, "").trim()) // Remove bullets and trim spaces
      .filter((item) => item.length > 0); // Remove empty lines

    setCoachingExcellence(update);
  }
}
                  // Check if the pressed key is not allowed
                }}
              />
              <div>
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  Exam structure
                </p>
                <Button
                  variant="contained"
                  color="success"
                  onClick={() => handleAddItem()}
                  disabled={isAddDisabled() || isError()}
                >
                  Add Exam Structure
                </Button>

                {examStructure.map((item, index) => (
                  <div key={index}>
                    <TextField
                      label={`Exam Component ${index + 1}`}
                      variant="outlined"
                      fullWidth
                      required
                      margin="normal"
                      onBlur={handleBlur}
                      name="component"
                      onChange={(e) => {
                        handleStructureChange(index, e);
                      }}
                      value={item.component}
                      slotProps={{
                        htmlInput: {
                          maxLength: 50,
                        },
                      }}
                      onKeyDown={(e) => {
                        // Prevent spaces as the first character
                        if (item.component.length === 0 && e.key === " ") {
                          e.preventDefault();
                          return;
                        }

                        // Check if the pressed key is not allowed
                      }}
                      //  onChange={(e) => handleArrayChange('examStructure', index, 'component', e.target.value)}
                    />
                    <TextField
                      label={`Exam Duration ${index + 1}`}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="duration"
                      required
                      onBlur={handleBlur}
                      onChange={(e) => {
                        handleStructureChange(index, e);
                      }}
                      value={item.duration}
                      slotProps={{
                        htmlInput: {
                          maxLength: 50,
                        },
                      }}
                      onKeyDown={(e) => {
                        // Prevent spaces as the first character
                        if (item.duration.length === 0 && e.key === " ") {
                          e.preventDefault();
                          return;
                        }

                        // Check if the pressed key is not allowed
                      }}
                      //   onChange={(e) => handleArrayChange('examStructure', index, 'duration', e.target.value)}
                    />

                    <TextField
                      label={`Exam Description ${index + 1}`}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="description"
                      value={
                        Array.isArray(item.description)
                          ? item.description
                              .map((value) => `• ${value}`)
                              .join("\n")
                          : item.description
                      }
                      //  onChange={(e) => handleArrayChange(`examStructure[${index}].description`, descIndex, null, e.target.value)}
                      multiline
                      slotProps={{
                        htmlInput: {
                          maxLength: 500,
                        },
                      }}
                      onKeyDown={(e) => {
                        const cursorPosition = e.target.selectionStart;
                        const value = e.target.value;
                        // Prevent spaces as the first character
                        if (item.description.length === 0 && e.key === " ") {
                          e.preventDefault();
                          return;
                        }
                        if (e.key === 'Backspace') {
                          const textBeforeCursor = value.slice(0, cursorPosition);
                          if (textBeforeCursor.endsWith("• ") || textBeforeCursor.endsWith("•")) {
                            e.preventDefault(); // Prevent the default behavior
                    
                            // Remove the last line if it's an empty bullet
                            const newValue = value.split("\n").slice(0, -1).join("\n");
                            handleDescriptionChange(index, { target: { value: newValue } });
                          }
                        }
                        // Check if the pressed key is not allowed
                      }}
                      rows={4}
                      required
                      onBlur={handleBlur}
                      onChange={(e) => handleDescriptionChange(index, e)}
                    />
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleDeleteItem(index)}
                    >
                      Delete Exam Structure {index + 1}
                    </Button>
                  </div>
                ))}
                <FormHelperText error>{error.component}</FormHelperText>
                <FormHelperText error>{error.duration}</FormHelperText>
                <FormHelperText error>{error.description}</FormHelperText>
              </div>

              <div>
                <p
                  style={{
                    fontSize: "18px",
                    fontWeight: "bold",
                  }}
                >
                  Academic Year
                </p>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleAddYear}
                  disabled={isAddMonth()}
                >
                  Add Academic Year
                </Button>
                {academicYear.map((item, index) => (
                  <div key={index}>
                    {/* <TextField
                      label={`Academic Year Month ${index + 1}`}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name={`academicYear[${index}].month`}
                      value={item.month}
                    /> */}
                    <div className="months">
                      <div>
                        <Select
                          labelId={`month-label-${index}`}
                          label="Month"
                          value={item.month}
                          name="month"
                          onChange={(e) => handleMonthChange(index, e)}
                        >
                          {months.map((month, idx) => (
                            <MenuItem key={idx} value={month}>
                              {month}
                            </MenuItem>
                          ))}
                        </Select>
                      </div>
                      <div>
                        <DatePicker
                          placeholder="Select the Dates"
                          style={{
                            height: "50px",
                          }}
                          multiple
                          value={item.dates}
                          format="MMMM DD YYYY"
                          sort
                          plugins={[<DatePanel />]}
                          onChange={(dates) => handleDateChange(dates, index)}
                        />
                      </div>
                    </div>
                    <div>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                          handleDeleteYear(index);
                        }}
                        style={{
                          marginTop: "10px",
                        }}
                      >
                        Delete Academic Year {index + 1}
                      </Button>
                    </div>
                  </div>
                ))}
                <FormHelperText error>{error.month}</FormHelperText>
                <FormHelperText error>{error.dates}</FormHelperText>
              </div>
              <div>
                <p style={{ fontSize: "18px", fontWeight: "bold" }}>
                  Package Information
                </p>
                <Button
                  variant="contained"
                  color="success"
                  onClick={handleAddPackage}
                  disabled={isAddPackage() || isPackageError()}
                >
                  Add Package
                </Button>

                {packageMonth.map((item, index) => (
                  <div key={index}>
                    <TextField
                      label={`Package Name ${index + 1}`}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="packageName"
                      onBlur={handleBlur}
                      value={item.packageName}
                      slotProps={{
                        htmlInput: {
                          maxLength: 20,
                        },
                      }}
                      onKeyDown={(e) => {
                        // Prevent spaces as the first character
                        if (item.packageName.length === 0 && e.key === " ") {
                          e.preventDefault();
                          return;
                        }

                        // Check if the pressed key is not allowed
                      }}
                      onChange={(e) => handlePackageMonthChange(index, e)}
                      //onChange={(e) => handlePackageChange(index, "packageName", e.target.value)}
                    />
                    <TextField
                      label={`Package Price ${index + 1}`}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="packagePrice"
                      value={item.packagePrice}
                      onBlur={handleBlur}
                      onChange={(e) => handlePackageMonthChange(index, e)}
                      slotProps={{
                        htmlInput: {
                          maxLength: 20,
                        },
                      }}
                      onKeyDown={(e) => {
                        const allowedKeys = [
                          "Backspace",
                          "ArrowLeft",
                          "ArrowRight",
                          "Delete",
                          "Tab",
                        ];
                        const allowedCharPattern = /^[0-9+]$/;
  
                        // Check if the pressed key is not allowed
                        if (
                          !allowedKeys.includes(e.key) &&
                          !allowedCharPattern.test(e.key)
                        ) {
                          e.preventDefault(); // Prevent the default action of the disallowed key
                        }
                        // Prevent spaces as the first character
                        if (item.packagePrice.length === 0 && e.key === " ") {
                          e.preventDefault();
                          return;
                        }

                        // Check if the pressed key is not allowed
                      }}

                      //  onChange={(e) => handlePackageChange(index, "packagePrice", e.target.value)}
                    />
                    <TextField
                      label={`Package Duration ${index + 1}`}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="packageDuration"
                      onBlur={handleBlur}
                      onChange={(e) => handlePackageMonthChange(index, e)}
                      value={item.packageDuration}
                      slotProps={{
                        htmlInput: {
                          maxLength: 20,
                        },
                      }}
                      onKeyDown={(e) => {
                        // Prevent spaces as the first character
                        if (
                          item.packageDuration.length === 0 &&
                          e.key === " "
                        ) {
                          e.preventDefault();
                          return;
                        }

                        // Check if the pressed key is not allowed
                      }}
                      // onChange={(e) => handlePackageChange(index, "packageDuration", e.target.value)}
                    />
                    <TextField
                      label={`Key Features ${index + 1}`}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      name="keyFeatures"
                      slotProps={{
                        htmlInput: {
                          maxLength: 500,
                        },
                      }}
                      onKeyDown={(e) => {
                        const cursorPosition = e.target.selectionStart;
                        const value = e.target.value;
                    
                        // Prevent spaces as the first character
                        if (value.length === 0 && e.key === " ") {
                          e.preventDefault();
                          return;
                        }
                    
                        // Allow deleting a bullet point
                        if (e.key === 'Backspace') {
                          const textBeforeCursor = value.slice(0, cursorPosition);
                          if (textBeforeCursor.endsWith("• ") || textBeforeCursor.endsWith("•")) {
                            e.preventDefault(); // Prevent the default behavior
                    
                            // Remove the last line if it's an empty bullet
                            const newValue = value.split("\n").slice(0, -1).join("\n");
                            handlePackageFeaturesChange(index, { target: { value: newValue } });
                          }
                        }
                      }}
                      value={
                        Array.isArray(item.keyFeatures)
                          ? item.keyFeatures
                              .map((value) => `• ${value}`)
                              .join("\n")
                          : item.keyFeatures
                      }
                      //  onChange={(e) => handlePackageChange(index, "keyFeatures", e.target.value)}
                      multiline
                      onChange={(e) => handlePackageFeaturesChange(index, e)}
                      onBlur={handleBlur}
                      rows={4}
                    />
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleDeletePackage(index)} // Delete package
                    >
                      Delete Package {index + 1}
                    </Button>
                  </div>
                ))}
                <FormHelperText error>{error.packageName}</FormHelperText>
                <FormHelperText error>{error.packagePrice}</FormHelperText>
                <FormHelperText error>{error.packageDuration}</FormHelperText>
                <FormHelperText error>{error.keyFeatures}</FormHelperText>
              </div>
            </form>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="contained" color="error" onClick={handleClose}>
              Close
            </Button>
            <Button
              variant="contained"
              color="success"
              onClick={handleSubmit}
              style={{
                marginLeft: "10px",
              }}
            >
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      </section>
    </main>
  );
};

export default ManageCourse;
