import React, { useState } from "react";
import { TextField, Button, Box } from "@mui/material";
import { Link } from "react-router-dom";
import toast from "react-hot-toast";
import { FormLabel } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import client from "../../Common/Client/Client";
import Loader from "../../Common/Loader/Loader";

const Founder = () => {
  const [name, setName] = useState("");
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [image, setImage] = useState(null);
  const [about, setAbout] = useState([{ value: "", error: "" }]);
  const [error, setError] = useState({
    name: "",
    role: "",
    image: "",
  });

  const errorMessage = (fieldName, fieldValue) => {
    let message;
    if (fieldName) {
      if (fieldValue === "") {
        message = "";
      }
    }

    if (fieldName === "name") {
      if (fieldValue.length < 3) {
        message = `Name is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "role") {
      if (fieldValue.length < 10) {
        message = `role is Invalid`;
      } else {
        message = "";
      }
    }
    return { message: message };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;

    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    if (name === "name") {
      setName(value);
    } else {
      setRole(value);
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setError((prevError) => ({
        ...prevError,
        [name]: `${name} is required`,
      }));
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024;

      if (!fileType.startsWith("image/")) {
        setImage(null);
        setError((pre) => {
          return { ...pre, image: "Please select an valid image file." };
        });
      } else if (fileSize > 1) {
        setImage(null);
        setError((pre) => {
          return { ...pre, image: "File size exceeds 1 MB." };
        });
      } else {
        setImage(file);

        setError((prev) => {
          return { ...prev, image: "" };
        });
      }
    }
  };

  //about

  const handleAboutChange = (index, e) => {
    const { value } = e.target;
    const newAbout = [...about];
    newAbout[index].value = value;

    if (value.length < 10) {
      newAbout[index].error = "About content must be at least 10 characters.";
    } else {
      newAbout[index].error = "";
    }

    setAbout(newAbout);
  };

  // Handle About field blur (to show errors when losing focus)
  const handleAboutBlur = (index, e) => {
    const { value } = e.target;
    const newAbout = [...about];
    if (value === "") {
      newAbout[index].error = "About content is required.";
    }
    setAbout(newAbout);
  };
  const addAboutField = () => {
    if (about.length >= 5) {
      toast.error("Maximum of 5 About fields can be added.");
      return;
    }

    const lastField = about[about.length - 1];
    if (lastField.value === "") {
      toast.error("Complete the current field.");
    } else if (lastField.error) {
      toast.error("Fix the error in about field");
    } else {
      setAbout([...about, { value: "", error: "" }]);
    }
  };

  // Delete About content field
  const deleteAboutField = (index) => {
    if (about.length <= 1) {
      toast.error("At least one About field must remain.");
      return;
    }

    const newAbout = [...about];
    newAbout.splice(index, 1);
    setAbout(newAbout);
  };

  const handleSubmit = () => {
    const hasError = about.some(field => field.value === "" );
    if (name === "" || role === "" || image === null || hasError) {
      toast.error("All fields are required.");
    } else if (error.name !== "") {
      toast.error("Name is required");
      toast.error(error.name);
    } else if (error.role !== "") {
      toast.error(error.role);
    } else if (error.image !== "") {
      toast.error(error.image);
    }else if ( about.some(field =>  field.error!=="")){
      toast.error("Fix the error in about field");
    }else {
      setLoading(true);
      sendData();
    }
  };

  const sendData = async () => {
    try {
      
      const formData = new FormData();
      formData.append("image", image);
      formData.append("name", name);
      formData.append("role", role);
      about.forEach((field, index) => {
      formData.append("about",field.value);})
    
      const response = await client.post("/founder/addfounder", formData, {
        withCredentials: true,
      });
      if (response.status === 200) {
        toast.success("Founders Added Successfully");
        setName("");
        setRole("");
        setImage(null);
        setLoading(false);
        setAbout([{ value: "", error: "" }]);
      }
    } catch (err) {
      setLoading(false);
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to add Founders details");
      }
    }
  };
  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Dashboard</h1>
        <nav
          style={{
            marginTop: "10px",
          }}
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Founders</li>
          </ol>
        </nav>
      </div>
      <section
        className="section dashboard"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "70px",
        }}
      >
        <div className="card" style={{ width: "100%", maxWidth: "400px" }}>
          <div
            className="card-body"
            style={{
              padding: "20px",
            }}
          >
            <h5
              className="card-title"
              style={{ textAlign: "center", marginBottom: "20px" }}
            >
              Add Founders
            </h5>
            <form>
              <Box sx={{ marginBottom: "20px" }}>
                <TextField
                  label="Name"
                  slotProps={{
                    htmlInput: {
                      maxLength: 20,
                    },
                  }}
                  fullWidth
                  variant="outlined"
                  name="name"
                  value={name}
                  onChange={handleChange}
                  error={!!error.name}
                  helperText={error.name}
                  onBlur={handleBlur}
                  onKeyDown={(e) => {
                    const allowedKeys = [
                      "Backspace",
                      "ArrowLeft",
                      "ArrowRight",
                      "Delete",
                      "Tab",
                      " ",
                    ];
                    const allowedCharPattern = /^[A-Za-z.,_-]$/;
                    // Prevent spaces as the first character
                    if (name.length === 0 && e.key === " ") {
                      e.preventDefault();
                      return;
                    }

                    // Check if the pressed key is not allowed
                    if (
                      !allowedKeys.includes(e.key) &&
                      !allowedCharPattern.test(e.key)
                    ) {
                      e.preventDefault(); // Prevent the default action of the disallowed key
                    }
                  }}
                />
              </Box>

              <Box sx={{ marginBottom: "20px" }}>
                <TextField
                  label="Role"
                  name="role"
                  multiline
     
                  slotProps={{
                    htmlInput: {
                      maxLength: 100,
                    },
                  }}
                  onKeyDown={(e) => {
                    // Prevent spaces as the first character
                    if (role.length === 0 && e.key === " ") {
                      e.preventDefault();
                      return;
                    }
                  }}
                  fullWidth
                  variant="outlined"
                  value={role}
                  onChange={handleChange}
                  error={!!error.role}
                  helperText={error.role}
                  onBlur={handleBlur}
                />
              </Box>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <h5 style={{ textAlign: "center" }}>About Content</h5>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "20px",
                  }}
                >
                  <Button
                    variant="contained"
                    color="success"
                    onClick={addAboutField}
                  >
                    Add
                  </Button>
                </Box>
              </div>

              {about.map((field, index) => (
                <Box
                  key={index}
                  sx={{
                    marginBottom: "20px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  <TextField
                    label={`About Content ${index + 1}`}
                    fullWidth
                    variant="outlined"
                    slotProps={{
                      htmlInput: {
                        maxLength: 500,
                      },
                    }}
                    rows={2}
                    multiline
                    value={field.value}
                    onChange={(e) => handleAboutChange(index, e)}
                    onBlur={(e) => handleAboutBlur(index, e)}
                    error={!!field.error}
                    helperText={field.error}
                    onKeyDown={(e) => {
                      const allowedKeys = [
                          "Backspace",
                          "ArrowLeft",
                          "ArrowRight",
                          "Delete",
                          "Tab",
                          " ",
                      ];
                      const allowedCharPattern = /^[A-Za-z.,-_ ]$/; // Allow letters, spaces, and some special characters
      
                      // Prevent spaces as the first character
                      if (field.value.length === 0 && e.key === " ") {
                          e.preventDefault();
                          return;
                      }
      
                      // Check if the pressed key is not allowed
                      if (!allowedKeys.includes(e.key) && !allowedCharPattern.test(e.key)) {
                          e.preventDefault(); // Prevent the default action of the disallowed key
                      }
                  }}
      
                  />
                  <Button
                    variant="outlined"
                    color="error"
                    onClick={() => deleteAboutField(index)}
                    disabled={about.length <= 1} // Disable for the last field
                    sx={{ marginLeft: "10px" }}
                  >
                    Delete
                  </Button>
                </Box>
              ))}

              <Box
                sx={{
                  marginBottom: "20px",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FormLabel
                  sx={{
                    marginBottom: "20px",
                  }}
                >
                  <span>Founder Image(choose one Image)</span>
                </FormLabel>
                <Button
                  component="label"
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Upload Image
                  <input
                    type="file"
                    accept="image/*"
                    name="image"
                    hidden
                    onChange={handleImageChange}
                  />
                </Button>
                {image && <p>{image.name}</p>}
                {error.image && (
                  <p style={{ color: "red", textAlign: "center" }}>
                    {error.image}
                  </p>
                )}
              </Box>

             

              <Box sx={{ display: "flex", justifyContent: "center" }}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Submit 
                </Button>
              </Box>
            </form>
          </div>
        </div>
      </section>
      {loading && <Loader />}
    </main>
  );
};

export default Founder;
