import { Link } from "react-router-dom";
import React, { useState } from "react";
import { Button, TextField, FormLabel } from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Grid from "@mui/material/Grid2";
import { styled } from "@mui/material/styles";
import toast from "react-hot-toast";
import client from "../../Common/Client/Client";
import Loader from "../../Common/Loader/Loader";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const Blogs = () => {
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState(null);
  const [error, setError] = useState({
    name: "",
    location: "",
    description: "",
    date: "",
    image:""
  });
  const [blogData, setBlogData] = useState({
    name: "",
    location: "",
    description: "",
    date: "",
  });

 
  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setError((prevError) => ({
        ...prevError,
        [name]: `${name} is required`,
      }));
    }
  };

   const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const fileType = file.type;
      const fileSize = file.size / 1024 / 1024; // Convert size to MB

      if (!fileType.startsWith("image/")) {
        setImage(null);
        setError((pre) => {
          return { ...pre, image: "Please select an valid image file." };
        });
      } else if (fileSize > 1) {
        setImage(null);
        setError((pre) => {
          return { ...pre, image: "File size exceeds 1 MB." };
        });
      } else {
        setImage(file);
        setError((prev) => {
          return { ...prev, image: "" };
        });
      }
    }
  };
  const handleClick = (e) => {
    e.target.value = null; 
  };

  const errorMessage = (fieldName, fieldValue) => {
    let message;
    if (fieldName) {
      if (fieldValue === "") {
        message = "";
      }
    }

    if (fieldName === "name") {
      if (fieldValue.length < 3) {
        message = `Name is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "location") {
      if (fieldValue.length < 3) {
        message = `Review is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "description") {
        if (fieldValue.length < 250) {
          message = `Description need above 250 character `;
        } else {
          message = "";
        }
      }
    return { message: message };
  };


  console.log(error)
  console.log(blogData.date)

  const handleChange = (e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;

    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    setBlogData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = () => {
   if(image===null || blogData.date==="" || blogData.description==="" || blogData.location==="" || blogData.name===""){
    toast.error("Please fill all the fields");
   }else if(error.date!==undefined || error.description!=="" || error.image!=="" || error.name!=="" || error.location!==""){
    toast.error("Please check error in  all the fields");
   }else{
    setLoading(true);
    giveBlog()
   }
  };
  const giveBlog= async()=>{
    try{
      const descriptionArray = blogData.description
      .split("\n")
      .map((item) => item.trim());
     console.log(descriptionArray)
     const formData=new FormData();
     formData.append("image",image);
     formData.append("date",blogData.date);
     descriptionArray.forEach((item) => {
      if (item.trim() !== "") { 
        formData.append("description", item);
      }
    })
     formData.append("location",blogData.location);
     formData.append("name",blogData.name);

     console.log(formData.get('description'));

      const response=await client.post('/blog/add',formData,{
        withCredentials:true
      });
      if(response.status===201){
        toast.success("Blog Added Successfully");
        setBlogData({name:"",date:"",location:"",description:""});
        setImage(null);
        setError((pre)=>({
          ...pre,
          name:"",
          date:"",
          location:"",
          description:"",
          image:""
        }))
        setLoading(false);
      }else{
        setLoading(false);
        toast.error("Failed to add blog");
      }

    }catch(err){
      if (err.response && err.response.status === 401) {
        setLoading(false);
        toast.error("Login again");
      } else {
        setLoading(false);
        toast.error("Failed to add blog details");
      }
    }
  }
  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Dashboard</h1>
        <nav
          style={{
            marginTop: "10px",
          }}
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Blogs</li>
          </ol>
        </nav>
      </div>
      <section
        className="section dashboard"
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "70px",
        }}
      >
        <div className="card" style={{ width: "100%" }}>
          <div
            className="card-body"
            style={{
              padding: "20px",
            }}
          >
            <h5
              className="card-title"
              style={{ textAlign: "center", marginBottom: "20px" }}
            >
              Add Blog
            </h5>
            <Grid container spacing={4} marginBottom={"20px"}>
              <Grid item size={{ xs: 12, sm: 6 }}>
                <TextField
                  label="Blog Name"
                  name="name"
                  fullWidth
                  error={!!error.name}
                  helperText={error.name}
                  value={blogData.name}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  style={{ marginBottom: "15px" }}
                  slotProps={{
                    htmlInput: {
                      maxLength: 100,
                    },
                  }}
                
                  onKeyDown={(e) => {
                    const allowedKeys = [
                      "Backspace",
                      "ArrowLeft",
                      "ArrowRight",
                      "Delete",
                      "Tab",
                      " ",
                    ];
                    const allowedCharPattern = /^[A-Za-z.,_-]$/;
                    // Prevent spaces as the first character
                    if (blogData.name.length === 0 && e.key === " ") {
                      e.preventDefault();
                      return;
                    }
              
                    // Check if the pressed key is not allowed
                    if (
                      !allowedKeys.includes(e.key) &&
                      !allowedCharPattern.test(e.key)
                    ) {
                      e.preventDefault(); // Prevent the default action of the disallowed key
                    }
                  }}
                />
              </Grid>
              <Grid item size={{ xs: 12, sm: 6 }}>
                <TextField
                  label="Location"
                  name="location"
                  fullWidth
                  onBlur={handleBlur}
                error={!!error.location}
                helperText={error.location}
                  value={blogData.location}
                  onChange={handleChange}
                  style={{ marginBottom: "15px" }}
                  slotProps={{
                    htmlInput: {
                      maxLength: 20,
                    },
                  }}
                
                  onKeyDown={(e) => {
                    const allowedKeys = [
                      "Backspace",
                      "ArrowLeft",
                      "ArrowRight",
                      "Delete",
                      "Tab",
                      " ",
                    ];
                    const allowedCharPattern = /^[A-Za-z._-]$/;
                    // Prevent spaces as the first character
                    if (blogData.location.length === 0 && e.key === " ") {
                      e.preventDefault();
                      return;
                    }
              
                    // Check if the pressed key is not allowed
                    if (
                      !allowedKeys.includes(e.key) &&
                      !allowedCharPattern.test(e.key)
                    ) {
                      e.preventDefault(); // Prevent the default action of the disallowed key
                    }
                  }}
                />{" "}
              </Grid>
            </Grid>
          
            <Grid container spacing={5} marginBottom={"20px"}>
              <Grid item size={{ xs: 12, sm: 6 }}>
                <div>
                  <FormLabel>
                    <p style={{ fontSize: "17px", color: "black" }}>
                      select the Date
                    </p>
                  </FormLabel>
                </div>
                <TextField
                  name="date"
                  placeholder="select Date"
                  type="date"
                  onBlur={handleBlur}
                  error={!!error.date}
                  helperText={error.date}
                  fullWidth
                  value={blogData.date}
                  onChange={handleChange}
                  style={{ marginBottom: "15px" }}
                />
              </Grid>
              <Grid item size={{ xs: 12, sm: 6 }}>
                <div>
                  <FormLabel>
                    <p style={{ fontSize: "17px", color: "black" }}>Image</p>
                  </FormLabel>
                </div>
                <Button
                  component="label"
                  role={undefined}
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<CloudUploadIcon />}
                  style={{ marginTop: "10px", marginLeft: "20px" }}
                >
                  Gallery Image
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleImageChange}
                    accept="image/*"
                    onClick={handleClick} // Reset value on click
                  />
                </Button>
                {image && <p>{image.name}</p>}
                {error.image && (
                  <div
                    style={{
                      color: "red",
                      marginLeft: "20px",
                      fontSize: "13px",
                    }}
                  >
                    {error.image}
                  </div>
                )}
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item size={{ xs: 12 }}>
                <TextField
                  label="Description"
                  name="description"
                  multiline
                  rows={4}
                  fullWidth
                  onBlur={handleBlur}
                  error={!!error.description}
                  helperText={error.description}
                  value={blogData.description}
                  onChange={handleChange}
                  style={{ marginBottom: "15px" }}
                
                
                  onKeyDown={(e) => {
                    
                    // Prevent spaces as the first character
                    if (blogData.description.length === 0 && e.key === " ") {
                      e.preventDefault();
                      return;
                    }
              
                    // Check if the pressed key is not allowed
                    
                  }}
                />
              </Grid>
            </Grid>

            <Button
              variant="contained"
              onClick={handleSubmit}
              style={{ display: "block", margin: "20px auto" }}
            >
              Submit
            </Button>
          </div>
        </div>
      </section>
      {loading && <Loader />}
    </main>
  );
};

export default Blogs;
