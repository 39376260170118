import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import client from "../../../Common/Client/Client";
import {  Table } from "react-bootstrap";
import { message, Popconfirm } from "antd";
import { Button, styled } from "@mui/material";
import toast from "react-hot-toast";

const ManageRegister = () => {
  const [register, setRegister] = useState([]);
  useEffect(() => {
    fetchRegister();
  },[]);

  const fetchRegister = async () => {
    try {
      const response = await client.get("/register/get",{
        withCredentials:true
      });
      if (response.status === 200) {
        setRegister(response.data);
      }
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to fetch register details");
      }
    }
  };


  const cancel = (e) => {
    message.error("Cancle delete");
  };


  const handleDelete = async(id) => {
    try{
      console.log(id)
        const response=await client.post('/register/delete',{
          id:id
        },{
          withCredentials:true
        })
        if(response.status===200){
            toast.success("Delete Success")
            setRegister(register.filter((register) => register._id !== id));
        }
    }catch(err){
        console.log(err)
                   
    }
  };
  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Dashboard</h1>
        <nav
          style={{
            marginTop: "10px",
          }}
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Manage/Register</li>
          </ol>
        </nav>
      </div>

      <div className="container mt-4">
        <h2>Manage Registrations</h2>
        <div className="table-responsive">
          <Table striped bordered hover responsive>
            <thead className="thead-dark">
              <tr>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone</th>
                <th>Course</th>
                <th>Package</th>
                <th>Actions</th>

              </tr>
            </thead>
            <tbody>
              {register.length > 0 ? (
                register.map((item) => (
                  <tr key={item.id}>
                    <td>{item.firstName}</td>
                    <td>{item.lastName}</td>
                    <td>{item.email}</td>
                    <td>{item.phone}</td>
                    <td>{item.course}</td>
                    <td>{item.package}</td>
                    <td>
                   
                      <Popconfirm
                      title="Delete the Image"
                      description="Are you sure to delete this Image?"
                      onConfirm={() => {
                        handleDelete(item._id);
                      }}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                    >
                      <Button variant="contained"    className="table-button" color="error">
                        Delete
                      </Button>
                    </Popconfirm>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="6" className="text-center">
                    No registrations found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
    </main>
  );
};

export default ManageRegister;
