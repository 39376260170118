import React, { Fragment, useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import client from "../../Common/Client/Client";
import TextField from "@mui/material/TextField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import bcrypt from "bcryptjs"; 
import toast from "react-hot-toast";
const UpdatePassword = (props) => {
  const { setAdmin } = props;
  const [loginDetails, setLoginDetails] = useState([]);
  const [id, setId] = useState("");
  const [password, setPassword] = useState("");
  const [cpassword, setCpassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [error, setError] = useState({
    password: "",
    confirmpassword: "",
  });
  const [oldPasswordHash, setOldPasswordHash] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    fetchLogin();
  }, []);

  console.log(id);

  const fetchLogin = async () => {
    try {
      const respose = await client.get("/admin/getlogin",{
        withCredentials:true
      });
      if (respose.status === 200) {
        setLoginDetails(respose.data);
        if (respose.data.length > 0) {
          setId(respose.data[0]._id);
          setOldPasswordHash(respose.data[0].password); 
        }
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to fetch admin details");
      }
    }
  };

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const errorMessage = (fieldName, fieldValue) => {
    let message;
    if (fieldName) {
      if (fieldValue === "") {
        message = "";
      }
    }

    if (fieldName === "password") {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passwordRegex.test(fieldValue)) {
        message = `${fieldName} is Invalid`;
      } else {
        message = "";
      }
    }
    if (fieldName === "confirmpassword") {
      const passwordRegex =
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
      if (!passwordRegex.test(fieldValue)) {
        message = `${fieldName} is Invalid`;
      } else if (password !== fieldValue) {
        message = "Passwords do not match";
      } else {
        message = "";
      }
    }
    return { message: message };
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    const err = errorMessage(name, value).message;

    setError((prevError) => ({
      ...prevError,
      [name]: err,
    }));
    if (name === "password") {
      setPassword(value);
    } else {
      setCpassword(value);
    }
  };

  const handleBlur = (e) => {
    const { name, value } = e.target;
    if (value === "") {
      setError((prevError) => ({
        ...prevError,
        [name]: `${name} is required`,
      }));
    }
  };

  const handleSubmit = async() => {
    if (password === "" || cpassword === "") {
      toast.error("Please fill all field");
    } else if (error.password !== "" || error.confirmpassword !== "") {
      setError((pre) => {
        return {
          ...pre,
          password: "Password is reqiured",
          confirmpassword: "Confirm Password is reqiured",
        };
      });
      setPassword("");
      setCpassword("");
    } else {
      const isSamePassword = await bcrypt.compare(password, oldPasswordHash);
      if (isSamePassword) {
        toast.error("New password cannot be the same as the old password");
      }else if (password !== cpassword){
        setError((pre) => {
          return {
            ...pre,
            
            confirmpassword:"Passwords do not match"
          };
        });
      } else {
        updateData();
      }
    }
  };

  const updateData = async () => {
    try {
      const response = await client.post("/admin/updatepassword", {
        id: id,
        password: password,
        confirmpassword: cpassword,
      },{
        withCredentials:true
      });
      if (response.status === 200) {
        toast.success("Password updated successfully");
        navigate("/")
        localStorage.removeItem("token");
        localStorage.removeItem("Username");
        localStorage.removeItem("tokenExpiration");
        setAdmin(null);
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
      } else {
        toast.error("Failed to update admin details");
      }
    }
  };

  return (
    <Fragment>
      <main id="main" className="main">
        <div className="pagetitle">
          <h1>Dashboard</h1>
          <nav
            style={{
              marginTop: "10px",
            }}
          >
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active">Update Password</li>
            </ol>
          </nav>
        </div>
        <section
          className="section dashboard"
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: "70px",
          }}
        >
          <div className="card" style={{ width: "100%", maxWidth: "400px" }}>
            <div
              className="card-body"
              style={{
                padding: "20px",
              }}
            >
              <h5
                className="card-title"
                style={{ textAlign: "center", marginBottom: "20px" }}
              >
                Update Password
              </h5>
              <div>
                <TextField
                  id="standard-basic"
                  className="form"
                  slotProps={{
                    htmlInput: {
                      maxLength: 12,
                    },
                  }}
                  label="Password"
                  variant="standard"
                  value={password}
                  type={showPassword ? "text" : "password"}
                  name="password"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={error.password ? error.password : ""}
                  error={!!error.password}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  margin="normal"
                />
                <TextField
                  id="standard-basic"
                  className="form"
                  slotProps={{
                    htmlInput: {
                      maxLength: 12,
                    },
                  }}
                  label="Confirm Password"
                  variant="standard"
                  value={cpassword}
                  type={showPassword ? "text" : "password"}
                  name="confirmpassword"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  helperText={
                    error.confirmpassword ? error.confirmpassword : ""
                  }
                  error={!!error.confirmpassword}
                  onKeyDown={(e) => {
                    if (e.key === " ") {
                      e.preventDefault();
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleClickShowPassword}>
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  fullWidth
                  margin="normal"
                />
                <Button
                  variant="contained"
                  onClick={handleSubmit}
                  style={{ display: "block", margin: "20px auto" }}
                >
                  update
                </Button>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Fragment>
  );
};

export default UpdatePassword;
