import React, { useState, useEffect } from "react";
import client from "../../../Common/Client/Client";
import { Link } from "react-router-dom";
import { message, Popconfirm } from "antd";
import { Button, styled } from "@mui/material";
import { Modal, Form } from "react-bootstrap";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import Loader from "../../../Common/Loader/Loader";
import toast from "react-hot-toast";
import './MangeImage.css'
const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});
const ManageImage = () => {
  const [image, setImage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState("");
  const [showAddImage, setShowAddImage] = useState(0);
  const [images, setImages] = useState(null);
  const [showImage, setShowImage] = useState(null);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState({
    image: "",
  });
  useEffect(() => {
    getImage();
  }, []);

  const getImage = async () => {
    try {
      const response = await client("/imagegallery/getimage");
      if (response.status === 200) {
        setImage(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpdate = async (id, value) => {
    setOpen(true);
    setImages(value.image);
    setId(id);
  };
  const handleClose = () => {
    setOpen(false);
    setImages(null);
    setShowAddImage(0);
    setId(null);
    setError({
      image: "",
    });
  };

  const cancel = (e) => {
    message.error("Cancle delete");
  };
  const handleDelete = async (id) => {
    try {
      const response = await client.post(`/imagegallery/deleteimage`, {
        id: id,
      },{
        withCredentials:true
      });
      if (response.status === 200) {
        setImage(image.filter((image) => image._id !== id));
      }
    } catch (err) {
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Failed to delete image gallery");
      }
    }
  };

  const handleImageChange = (e) => {
    const image = e.target.files[0];
    if (image) {
      const fileType = image.type;
      const fileSize = image.size / 1024 / 1024; // Convert file size to MB

      if (!fileType.startsWith("image/")) {
        setError((prev) => {
          return { ...prev, image: "Please select a valid image file" };
        });
      } else if (fileSize > 1) {
        setError((prev) => {
          return { ...prev, image: "File size exceeds 1 MB" };
        });
      } else {
        var reader = new FileReader();
        reader.onload = function () {
          setImages(image);
          setShowImage(reader.result);
        };
        reader.readAsDataURL(image);
        setError((prev) => {
          return { ...prev, image: "" };
        });
      }
    }
  };

  const changeImage = async () => {
    if (images === null) {
      toast.error("Image Not found");
    } else if (error.image !== "") {
      setError((pre) => {
        return { ...pre, image: "Image is required" };
      });
    } else {
      updateImage();
    }
  };

  const updateImage = async () => {
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("image", images);
      formData.append("id", id);
      const response = await client.post("/imagegallery/updateimage", formData,{
        withCredentials:true
      });
      if (response.status === 200) {
        const updateimage = image.map((value) => {
          if (value._id === id) {
            return {
              ...value,
              image: response.data.image, 
            };
          }
          return value;
        });
        setImage(updateimage)
        toast.success("Image Update successfully");
        setOpen(false);
        setImages(null);
        setShowAddImage(0);
        setId(null);
        setLoading(false);

        setError({
          image: "",
        });
      }
    } catch (err) {
    
      if (err.response && err.response.status === 401) {
        toast.error("Login again");
        setLoading(false);
      } else {
        setLoading(false);
        toast.error("Failed to update image gallery");
      }
    }
  };

  return (
    <main id="main" className="main">
      <div className="pagetitle">
        <h1>Dashboard</h1>
        <nav
          style={{
            marginTop: "10px",
          }}
        >
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active">Manage/Images</li>
          </ol>
        </nav>
      </div>

      <div
        style={{
          padding: "20px",
          background: "white",
          boxShadow: "0 0 10px lightgray",
        }}
      >
        <h3>Manage Images</h3>
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr style={{ borderBottom: "2px solid #ddd", textAlign: "center" }}>
              <th style={{ padding: "8px" }}>Image</th>
              <th style={{ padding: "8px" }}>Actions</th>
            </tr>
          </thead>
          <tbody>
            {image.length > 0 ? (
              image.map((image) => (
                <tr key={image.id} style={{ borderBottom: "1px solid #ddd" }}>
                  <td style={{ padding: "8px" }}>
                    <img
                      src={image.image}
                      alt={image.name}
                      style={{ width: "100px", height: "100px" }}
                    />
                  </td>

                  <td  style={{ padding: "8px", }} >
                                     <Button
                      variant="contained"
                      color="primary"
                      onClick={() => handleUpdate(image._id, image)}
                      style={{ marginRight: "10px" }}
                      className="table-button"
                    >
                      Update
                    </Button>
                    <Popconfirm
                      title="Delete the Image"
                      description="Are you sure to delete this Image?"
                      onConfirm={() => {
                        handleDelete(image._id);
                      }}
                      onCancel={cancel}
                      okText="Yes"
                      cancelText="No"
                      className="table-button"
                    >
                      <Button variant="contained" color="error" className="table-button">
                        Delete
                      </Button>
                    </Popconfirm>
 
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" style={{ textAlign: "center", padding: "8px" }}>
                  No images available
                </td>
              </tr>
            )}
          </tbody>
        </table>
        <div>
          <Modal show={open} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title>Image</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form>
                <div className="update-form">
                  <Form.Group>
                    <div className="up-main-image" style={{ width: "100%" }}>
                      <div>
                      <p>Image</p>
                      </div>
                      <div style={{ marginTop: "20px" }}>
                        {showAddImage === 1 ? (
                          <>
                            <Button
                              component="label"
                              role={undefined}
                              variant="contained"
                              tabIndex={-1}
                              startIcon={<CloudUploadIcon />}
                              style={{ marginTop: "10px" }}
                              disabled={images !== null}
                            >
                              change Image
                              <VisuallyHiddenInput
                                type="file"
                                accept="image/*"
                                onChange={handleImageChange}
                                onClick={(e)=>{
                                  e.target.value = null;
                                }}
                              />
                            </Button>
                            <div>
                              {showImage === null ? (
                                <></>
                              ) : (
                                <div
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-evenly",
                                    alignItems: "center",
                                    marginTop: "10px",
                                  }}
                                >
                                  <img
                                    src={showImage}
                                    alt=""
                                    width="100px"
                                    height="100px"
                                  />
                                  <Button
                                    color="error"
                                    variant="contained"
                                    onClick={() => {
                                      setShowImage(null);
                                      setImages(null);
                                    }}
                                  >
                                    Delete
                                  </Button>
                                </div>
                              )}
                            </div>
                          </>
                        ) : (
                          <div className="image-delete-button">
                            <img
                              src={images}
                              alt=" "
                              width="100px"
                              height="100px"
                            />
                            <Button
                              variant="contained"
                              color="error"
                              onClick={() => {
                                setShowAddImage(1);
                                setImages(null);
                              }}
                              style={{
                                marginLeft: "10px",
                              }}
                            >
                              Delete
                            </Button>
                          </div>
                        )}
                        {error.image && (
                          <div
                            style={{
                              color: "red",
                              marginLeft: "20px",
                              fontSize: "13px",
                            }}
                          >
                            {error.image}
                          </div>
                        )}
                      </div>
                    </div>
                  </Form.Group>
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="contained" onClick={changeImage} color="success">
                Save changes
              </Button>
            </Modal.Footer>
            {loading && <Loader />}
          </Modal>
        </div>
      </div>
    </main>
  );
};

export default ManageImage;
