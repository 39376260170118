import { Toaster } from "react-hot-toast";
import React from "react";
import { useEffect, useState } from "react";
import "./App.css";
import Login from "./Components/Pages/Login/Login";
import route from "./Components/Common/Route/Route";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HeaderAllow from "./Components/Common/Route/HeaderAllow";
import { useNavigate } from "react-router-dom";

function App() {
  const [admin, setAdmin] = useState(null);

  return (
    <div className="App">
      <BrowserRouter>
        <AppContent setAdmin={setAdmin} admin={admin} />
      </BrowserRouter>
    </div>
  );
}




function AppContent({ setAdmin,admin }) {
  const navigate = useNavigate();

console.log(admin)
  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      const expirationTime = localStorage.getItem("tokenExpiration");
      if (expirationTime && Date.now() > expirationTime) {
        localStorage.removeItem("Username");
        localStorage.removeItem("token");
        localStorage.removeItem("tokenExpiration");
        setAdmin(null); 
      } else {
        setAdmin(token); 
      }
    } else {
      setAdmin(null);
      navigate("/");
    }
  }, [navigate, setAdmin]);

  return (
    <>
      <Toaster
        toastOptions={{
          success: {
            style: {
              duration: 3000,
              background: "green",
              color: "white",
            },
          },
          error: {
            style: {
              duration: 3000,
              background: "red",
              color: "white",
            },
          },
        }}
      />
      {admin === null ? (
        <Login setAdmin={setAdmin} />
      ) : (
        <>
          <HeaderAllow setAdmin={setAdmin} />
          <Routes>
            {route.map((e, index) => {
              return <Route key={index} path={e.path}   element={React.cloneElement(e.element, { setAdmin })}/>;
            })}
          </Routes>
        </>
      )}
    </>
  );
}


export default App;